import React, { useState, useRef } from 'react';
import { Button } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

export const RegionLoadButton = ({ onFileUpload }) => {
  const [file, setFile] = useState(null);
  const inputRef = useRef(null);

  const handleFileUpload = e => {
    const file = e.target.files[0];

    if (!file) {
      return;
    }

    const reader = new FileReader();

    reader.onload = () => {
      const fileContent = reader.result;
      try {
        const fileObject = JSON.parse(fileContent);
        setFile(fileObject);
        onFileUpload(fileObject);
      } catch (error) {
        console.error('Error parsing JSON file', error);
      }
    };

    reader.readAsText(file);
  };

  const handleClick = () => {
    inputRef.current.click();
  };

  return (
    <>
      <input
        type="file"
        style={{ display: 'none' }}
        onChange={handleFileUpload}
        ref={inputRef}
        accept=".json,.geojson"
      />
      <Tooltip title="Load boundary file from local machine">
        <Button 
          variant="contained" color="info" 
          size="small" onClick={handleClick} style={{ margin: 5 }}>
          Load
        </Button>
      </Tooltip>
    </>
  );
}

export default RegionLoadButton;
