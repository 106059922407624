// top level panel for factor analysis 
import { useState, useEffect, useContext} from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { v4 as uuidv4 } from 'uuid';
import { load as yamlload } from 'js-yaml';

import { addFactorLayer, layerDefaults } from './LayerControl';
import { FactorLayerPanel } from './FactorLayerPanel';
import { CatalogContext } from '../CatalogContext';

import { ThemeProvider } from '@mui/material/styles';
import { darkTheme, pmtile_metadata } from "../Util";


const FactorControlPanel = ({ map, dsn }) => {
 
  const catalog = useContext(CatalogContext);
  const [state, setState] = useState({
    dsn: dsn,
    datasets: [],
    currentDataset: null,   // single dataset currenlty picked from Autocomplete
    currentLayers: []
  });
  const [isPanelActive, setIsPanelActive] = useState(false);
  const [factorAliases, setFactorAliases] = useState({});

  const fetchDatasetList = async () => {
    const url = `https://htsz8cbyj8.execute-api.us-east-1.amazonaws.com/dev/lists3?bucket=${dsn}`;
    const res = await fetch(url);
    const data = await res.json();
    if (Array.isArray(data)) {
      setState({ ...state, datasets: data })
    }
  }
  const fetchPMList = async (pmfolder) => {
    const dsn = catalog.id;
    const endpoint = `https://htsz8cbyj8.execute-api.us-east-1.amazonaws.com/dev/lists3`;
    const url = `${endpoint}?bucket=${dsn}&mode=object&folder=${pmfolder}`;
    const res = await fetch(url);
    const data = await res.json();
    const host = `https://${dsn}.s3.amazonaws.com`;
    console.log(url);

    if (Array.isArray(data)) {
      Promise.all(data
        .filter((f)=> f.endsWith('.pmtiles'))
        .map(async (f) => {
          const pmurl = `${host}/${f}`;
          const meta = await pmtile_metadata(pmurl);
          const desc = meta['vector_layers'][0];
          const factors = create_factor_list(desc['fields']);
          return {
            id: f.split("/").pop(),
            tileDir: f,
            host: host,
            metadata: desc ,
            factors: factors,
            format: 'pmtiles',
            meta: meta,
          }
        })
      ).then(results => {
        setState({ ...state, datasets: results})
        console.log(results);
      })
    }
  }

  useEffect(() => {
    // fetchDatasetList();
    if (catalog?.assets?.factor?.hexagon) {
      fetchPMList(catalog.assets.factor.hexagon);
    } else {
      fetchDatasetList();
    }
  }, []);

  const fetchFactorAliases = async () => {
    const url = `https://${dsn}.s3.amazonaws.com/catalog/factor-alias.yaml`;
    const res = await fetch(url);
    const data = await res.text();
    const config = yamlload(data);
    if (
      'factor:id_to_alias' in config.properties
    ) {
      setFactorAliases(config.properties['factor:id_to_alias']);
    } else {
      setFactorAliases({});
    }
  }
  useEffect(() => {
    fetchFactorAliases();
  }, [isPanelActive]);


  const hidePanel = () => {
    setIsPanelActive(!isPanelActive)
  }
  const handleSetCurrentDataset = (id) => {
    const currentDataset = state.datasets.find((ds) => ds.id === id);
    currentDataset.uuid = uuidv4();
    setState({ ...state, currentDataset: currentDataset })
  }
  const handleLoadClick = () => {
    const id = uuidv4();
    const layerInfo = {
      id: id,
      dataset: state.currentDataset,
      sourceId: `factor-${id}`,
      layerId: `l-factor-${id}`,
      layerState: layerDefaults
    }
    addFactorLayer(map.current, layerInfo);
    setState({
      ...state,
      currentLayers: [...state.currentLayers, layerInfo]
    })
  }
  const removeLayer = (layerInfo) => {
    // remove layer from currentLayers
    const updatedLayers = state.currentLayers.filter(l => l.id !== layerInfo.id);
    setState({ ...state, currentLayers: updatedLayers })
  }
  const updateLayerState = (id, layerState) => {
    // update layerState 
    const updatedLayers = state.currentLayers.map((layer) => {
      if (layer.id !== id) {
        return layer
      } else {
        return { ...layer, layerState: layerState };
      }
    })
    setState({ ...state, currentLayers: updatedLayers })
  }

  return (<div>
    <h3 onClick={hidePanel}>Factors</h3>
    <div
      style={{ display: isPanelActive ? 'block' : 'none' }}
    >
      <div>
        <ThemeProvider theme={darkTheme}>
          <Autocomplete freeSolo
            options={(state.datasets ? state.datasets.map((ds) => ds.id) : [])}
            renderInput={(params) =>
              <TextField
                {...params}
                label="factor dataset"
                size="small"
                margin="dense"
              />}
            onChange={(e, data) => handleSetCurrentDataset(data)}
          />
        </ThemeProvider>
        <input
          type='button'
          style={{ display: 'inline-block' }}
          value="load"
          onClick={handleLoadClick}
        />
      </div>
      <div style={{
        display: 'flex',
        flexDirection: 'column-reverse'
      }}
      >
        {state.currentLayers.map((layer) => {
          return (<FactorLayerPanel
            key={layer.id}
            map={map.current}
            layerInfo={layer}
            removeLayer={removeLayer}
            factorAlias={
              layer.dataset.id in factorAliases ? 
              factorAliases[layer.dataset.id] : {}}
          />)
        })}
      </div>
    </div>
  </div >)
}

const create_factor_list = (fields) => {
  const fieldList = Object.entries(fields).map(([originalFieldName, fieldType]) => ({
      originalFieldName,
      fieldType,
  }));
  // Step 2: Filter fields with field names matching the pattern
  const filteredFields = fieldList.filter(({ originalFieldName }) =>
      /^\d+$/.test(originalFieldName) || /^Topic_\d+$/.test(originalFieldName)
  );
  // Step 3 and 4: Create new objects with original field name and label
  const result = filteredFields.map(({ originalFieldName }) => ({
      'id': originalFieldName,
      label: originalFieldName.startsWith('Topic_') ? 
        originalFieldName : 
        `Topic_${originalFieldName}`,
  }));
  return result
}
export default FactorControlPanel;
