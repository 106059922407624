import React, { useEffect, useState, useContext, useRef } from 'react';

import * as dfd from "danfojs";
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import { useTheme } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import TableViewIcon from '@mui/icons-material/TableView';

import { DEViewer } from "./DEViewer";

import { MapContext } from '../MapContext';
import { CatalogContext } from '../CatalogContext';

const FactorView = () => {
  const map = useContext(MapContext);
  const catalog = useContext(CatalogContext);
  const fileInput = useRef();
  const theme = useTheme();

  const [open, setOpen] = useState(false);
  const [datasets, setDatasets] = useState([]);
  const [currentDataset, setCurrentDataset] = useState('');
  const [df, setDf] = useState(null);

  useEffect(() => {
    fetchDEList(catalog.assets.factor.DE); 
    // setDatasets(catalog.assets.factor.DE);
  }, [])

  const fetchDEList = async (defolder) => {
    const dsn = catalog.id;
    const endpoint = `https://htsz8cbyj8.execute-api.us-east-1.amazonaws.com/dev/lists3`;
    const url = `${endpoint}?bucket=${dsn}&mode=object&folder=${defolder}`;
    const res = await fetch(url);
    const data = await res.json();
    const host = `https://${dsn}.s3.amazonaws.com`;

    if (Array.isArray(data)) {
      const tsvs = data
      .filter((f) => f.endsWith('.tsv'))
      .map((f) => `${host}/${f}`);
      console.log(tsvs);
      setDatasets(tsvs);
    }
  }

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleDatasetClick = (_, d) => {
    setCurrentDataset(d);
    dfd.readCSV(d)
      .then(cdf => {
        const log = cdf["pval"].add(Number.MIN_VALUE).apply((x) => { return - Math.log10(x) });
        cdf.addColumn("-log(pval)", log, { inplace: true });
        setDf(cdf);
      });
  }
  const handleFileChosen = (event) => {
    let file = event.target.files[0];
    dfd.readCSV(file).then(cdf => {
      const log = cdf["pval"].add(Number.MIN_VALUE).apply((x) => { return - Math.log10(x) });
      cdf.addColumn("-log(pval)", log, { inplace: true });
      setDf(cdf);
      setCurrentDataset(file.name);
    });
  }

  return (
    <div>
      <Box
        sx={{ '& > :not(style)': { m: 0.5 } }}
        className='actionBox'
        style={{ right: 180 }}
      >
        <Fab
          variant="extended"
          aria-label="add"
          onClick={open ? handleDrawerClose : handleDrawerOpen}
        >
          Factor View
          <TableViewIcon />
        </Fab>
      </Box>
      <Drawer
        PaperProps={{
          sx: { height: .5 }
        }}
        sx={{
          flexShrink: 0,
        }}
        variant="persistent"
        anchor="bottom"
        open={open}
      >
        <Grid container spacing={3} sx={{ padding: 2, flexGrow: 1 }} >
          <Grid item xs={2}>
            {datasets.map(d => (
              <div
                key={d}
                style={{
                  wordWrap: 'break-word',
                  cursor: "pointer",
                  color: d == currentDataset ? "" : "gray",
                  margin: 5,
                  padding: 5,
                  borderRadius: 7,
                  borderStyle: 'dotted'
                }}
                onClick={(e) => handleDatasetClick(e, d)}
              >{d.split("/").pop()}</div>
            ))}
            <div>
              <button onClick={() => { fileInput.current.click() }}>Open Local File</button>
              <input
                type='file'
                ref={fileInput}
                style={{ display: 'none' }}
                onClick={(event) => { event.target.value = null }}
                onChange={handleFileChosen}
              />
            </div>
          </Grid>
          <Grid item xs={10} >
            <Grid container
              wrap="nowrap" spacing={1} sx={{ overflow: 'auto', marginTop: 0 }}
            >
              <DEViewer dataset={currentDataset} df={df} />
            </Grid>
          </Grid>
        </Grid>
      </Drawer>
    </div >
  );
}

export default FactorView;
