import React, { useEffect, useState, useContext } from 'react';
import { Link as LinkRouter } from 'react-router-dom';
import { DataListContext } from '../AppContext';

import {
  isUserAllowed,
} from "./Util";


import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import Amplify, { Auth, Hub } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import awsconfig from '../aws-exports';

Amplify.configure(awsconfig);

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://sites.google.com/a/umich.edu/lee-lab/">
        University of Michigan
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export default function Album({ handleUserChange }) {
  // handling auth data
  const [user, setUser] = useState(null);
  const datalist = useContext(DataListContext);

  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          getUser().then(userData => {
            setUser(userData);
            handleUserChange(userData);
          });
          break;
        case 'signOut':
          setUser(null);
          console.log("sign out");
          handleUserChange(null);
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          console.log('Sign in failure', data);
          break;
      }
    });

    getUser().then(userData => {
      setUser(userData);
      handleUserChange(userData);
    });

  }, []);

  function getUser() {
    return Auth.currentAuthenticatedUser()
      .then(userData => userData)
      .catch(() => {
        handleUserChange(null);
        console.log('Not signed in');
      });
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar position="static">
        <Toolbar>
          <MenuIcon sx={{ mr: 2 }} />
          <Typography variant="h6" color="inherit" noWrap
            sx={{ flexGrow: 1 }}
          > Seq-Scope
          </Typography>
          {user ? (
            <div> {user.attributes.email}
              <Button color="inherit" onClick={() => Auth.signOut()}>Sign Out</Button>
            </div>
          ) : (
            <Button color="inherit" onClick={() => Auth.federatedSignIn(
              { provider: CognitoHostedUIIdentityProvider.Google })}
            >Sign In with Google Account</Button>
          )}
        </Toolbar>
      </AppBar>
      <main>
        {/* Hero unit */}
        <Box
          sx={{
            bgcolor: 'background.paper',
            pt: 8,
            pb: 6,
          }}
        >
          <Container maxWidth="sm">
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="text.primary"
              gutterBottom
            >
              Seq-Scope
            </Typography>
            <Typography variant="h6" align="center" color="text.secondary" paragraph>
              Spatial barcoding technologies have the potential to reveal histological
              details of transcriptomic profiles; however, they are currently limited
              by their low resolution. Here, we report Seq-Scope, a spatial barcoding
              technology with a resolution comparable to an optical microscope.
              Seq-Scope is based on a solid-phase amplification of randomly barcoded
              single-molecule oligonucleotides using an Illumina sequencing platform.
              The resulting clusters annotated with spatial coordinates are processed
              to expose RNA-capture moiety. These RNA-capturing barcoded clusters define
              the pixels of Seq-Scope that are ∼0.5–0.8 μm apart from each other.
            </Typography>
            <Stack
              sx={{ pt: 4 }}
              direction="row"
              spacing={2}
              justifyContent="center"
            >
              <Button
                variant="outlined"
                href="https://www.sciencedirect.com/science/article/abs/pii/S0092867421006279"
                target="_blank"
              >Paper</Button>
              <Button
                variant="outlined"
                href="https://github.com/seqscope"
                target="_blank"
              >Code</Button>
            </Stack>
          </Container>
        </Box>
        <Container sx={{ py: 8 }} maxWidth="md">
          {user ? (
            <Grid container spacing={4}>
              {datalist.map((d) => (
                <Grid item key={d.id} xs={12} sm={6} md={6}>
                  {isUserAllowed(d, user) ?
                    <Card
                      sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                    >
                      <CardContent sx={{ flexGrow: 1 }}
                      >
                        <Typography gutterBottom variant="h5" component="h2">
                          {d.id}
                        </Typography>
                        <Typography>
                          {d.desc}
                        </Typography>
                      </CardContent>
                      <CardMedia
                        component="img"
                        sx={{
                          pt: '0%',
                        }}
                        // image={'img' in d ? d.img : `https://${d.id}.s3.amazonaws.com/raster/${d.id}.png`}
                        image={`https://cartoscope-thumbnails.s3.amazonaws.com/thumbnails/${d.id}.png`}
                        alt="dataset image"
                        onError={e=>{
                          e.target.style.display='none'}}
                      />
                      <CardActions>
                        <Button
                          size="small"
                          component={LinkRouter}
                          to={`/map/${d.id}`}
                        > View</Button>
                      </CardActions>
                    </Card> : ""}
                </Grid>
              ))}
            </Grid>) :
            (
              <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }} >
                <CardContent>
                  <Typography gutterBottom variant="h6" component="h2">
                    Sign Up
                  </Typography>
                  <Typography color="text.secondary" paragraph>
                    You need to sign up first to explore the sample dataset.
                    Please use Google or Google Workspace account to sign up.
                    If you already signed up, please sign in with the account you signed up.
                  </Typography>
                </CardContent>
                <CardActions >
                  <Button variant="contained" onClick={() => Auth.federatedSignIn(
                    { provider: CognitoHostedUIIdentityProvider.Google })}
                  >Sign Up / Sign In</Button>
                </CardActions>
              </Card>
            )}
        </Container>
      </main>
      {/* Footer */}
      <Box sx={{ bgcolor: 'background.paper', p: 6 }} component="footer">
        <Typography variant="h6" align="center" gutterBottom>
          Seq-Scope
        </Typography>
        <Typography
          variant="subtitle1"
          align="center"
          color="text.secondary"
          component="p"
        >
          Lee Lab
        </Typography>
        <Copyright />
      </Box>
      {/* End footer */}
    </ThemeProvider >
  );
}

