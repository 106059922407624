import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import MapView from './components/MapView.js';
import { DataListContext } from './AppContext';
import DataList from './components/DataList.js';
import './App.css';

const App = () => {
  const [dataList, setDataList] = useState({});
  const [userState, setUserState] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const handleUserChange = (user) => {
    setUserState(user);
  }

  const fetchDataList = async () => {
    try {
      const url = process.env.REACT_APP_DATALIST_API_ENDPOINT;
      const res = await fetch(url);
      const rawData = await res.json();
      const data = rawData['datasets'];
      setDataList(data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
  useEffect(() => fetchDataList(), []);

  return (
    <Router>
      <DataListContext.Provider value={dataList || {}}>
        <Switch>
         <>
          {isLoading ? ( // Add conditional rendering
            <p>Loading...</p>
          ) : (
            <>
              <Route exact path="/">
                  <DataList handleUserChange={handleUserChange} />
              </Route>
              <Route exact path="/index.html">
                  <DataList handleUserChange={handleUserChange}/>
              </Route>
              <Route path="/map/:dsn">
                <MapView
                  user={userState}
                />
              </Route>
            </>
          )}
         </>
        </Switch>
      </DataListContext.Provider>
    </Router>
  )
}

export default App;

