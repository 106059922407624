import { useState, useContext, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import { ThemeProvider } from '@mui/material/styles';
import { darkTheme } from "../Util";

import { MapContext } from '../MapContext';
import { CatalogContext } from '../CatalogContext';
import { RasterEyeDropper } from './RasterEyeDropper';
import { v4 as uuidv4 } from 'uuid';
import Papa from 'papaparse'; // A popular CSV parsing library

import { addRasterLayer, layerDefaults } from './LayerControl';

export const RasterSourceControl = ({
  layers, setLayers, buttonLabel, layerInfo }) => {

  const [sourceParams, setSourceParams] = useState(
    layerInfo?.sourceParams ?
      layerInfo.sourceParams :
      layerDefaults.sourceParams
  );
  const [colorDef, setColorDef] = useState([]);

  const fetchColorDef = async (url) => {
    const colorDefTsvUrl = url.replace('-topk', '').replace('.tif', '.rgb.tsv');
    const res = await fetch(colorDefTsvUrl);
    const text = await res.text();
    const result = Papa.parse(
      text,
      { header: true, delimiter: "\t", skipEmptyLines: true });
    setColorDef(result.data)
    const exp = generateColorMap(result.data);
    setColorDict(exp);
  }

  useEffect(() => {
    fetchColorDef(sourceParams.url);
  }, [sourceParams.url]);

  const setRasterUrl = (url) => {
    setSourceParams({
      ...sourceParams,
      url: url
    });
  };
  
  const setRasterColormap = (colormap_text) => {
    if (colormap_text === '_topk') {
      setSourceParams(current => {
        const exp = generateColorMap(colorDef);
        setColorDict(exp);
        setSourceParams(current => {
          return {
            ...current,
            colormap_text: '_topk',  
            }
        });
      })
    } else if (colormap_text === '_custom') {
      setSourceParams(current => {
        setSourceParams(current => {
          const { colormap_name, ...rest } = current;
          return {
            ...rest,
            colormap_text: '_custom',  
            }
        });
      })
    } else {
      setSourceParams(current => {
        const { colormap, ...rest } = current;
        return {
          ...rest,
          colormap_text: colormap_text,
          colormap_name: colormap_text
        }
      })
    }
  };
  
  const setColorDict = (colorDict) => {
    setSourceParams(current => {
      const { colormap_name, ...rest } = current;
      return {
        ...rest,
        colormap: encodeURIComponent(colorDict)
        }
    });
  };

  return (
    <div>
      <div style={{ display: 'flex' }}>
        <RasterUrlBox
          rasterUrl={sourceParams.url} setRasterUrl={setRasterUrl} layerInfo={layerInfo} />
      </div>
      
      <div 
        style={{ display: 'flex' }}
      >
        colormap:
        <ColormapBox
          colormap_text={sourceParams.colormap_text} setColormap={setRasterColormap} />
      </div>
      <div 
        style={{ display: 'flex' }}
        className={sourceParams?.colormap_text !== '_custom' ? "disable" : ""} 
      >
        custom cmap:
        <ColorDictBox
          colorDict={sourceParams.colormap} setColorDict={setColorDict} />
      </div>
       <div style={{ display: 'flex' }}>
        <AddRasterLayerPanelButton
          sourceParams={sourceParams}
          layerInfo={layerInfo}
          label={buttonLabel}
          layers={layers} setLayers={setLayers}
        />
      </div>
      {buttonLabel == 'update' ?
        "" :
        <RasterEyeDropper sourceParams={sourceParams} />
      }
      {(['_topk', '_custom'].includes(sourceParams.colormap_text) && 
        buttonLabel == 'update') ? 
          <ColorLegend colorDict={sourceParams.colormap} /> : ''}
    </div>)
}

const RasterUrlBox = ({ rasterUrl, setRasterUrl, layerInfo }) => {
  const catalog = useContext(CatalogContext);
  const dsn = catalog.id;
  let folder = "raster/factor/"
  if (catalog?.assets?.factor?.pixel) {
    folder = catalog.assets.factor.pixel;
  }

  const [datasetList, setDatasetList] = useState([]);
  const [currentDataset, setCurrentDataset] = useState(
    layerInfo?.sourceParams?.url ?
      layerInfo.sourceParams.url :
      ''
  );

  const fetchDatasetList = async () => {
    const dsn = catalog.id;
    const endpoint = `https://htsz8cbyj8.execute-api.us-east-1.amazonaws.com/dev/lists3`;
    const url = `${endpoint}?bucket=${dsn}&mode=object&folder=${folder}`;
    const res = await fetch(url);
    const data = await res.json();
    if (Array.isArray(data)) {
      const tiffs = data.filter((f) => f.endsWith('.tif'));
      setDatasetList(tiffs);
    }
  }
  useEffect(() => {
    fetchDatasetList();
  }, []);

  const handleSetCurrentDataset = (ds) => {
    const baseUrl = `https://${dsn}.s3.amazonaws.com`;
    const fullDatasetUrl = `${baseUrl}/${folder}${ds}`;
    setCurrentDataset(fullDatasetUrl);
  };

  useEffect(() => {
    setRasterUrl(currentDataset)
  }, [currentDataset])

  return (
    <ThemeProvider theme={darkTheme}>
      <Autocomplete
        style={{
          marginLeft: 'auto',
          width: '100%'
        }}
        freeSolo
        options={(datasetList ? datasetList.map((f) => f.replace(folder, '')) : [])}
        renderInput={(params) =>
          <TextField
            {...params}
            label={rasterUrl.split('/').pop()}
            size="small"
            margin="dense"
          />}
        onChange={(e, data) => handleSetCurrentDataset(data)}
      />
    </ThemeProvider>
  )
}

const ColormapBox = ({ colormap_text, setColormap }) => {
  const handleChange = (d) => {
    setColormap(d);
  }
  const colormaps = ['_topk', '_custom'];
  return (
    <ThemeProvider theme={darkTheme}>
      <Autocomplete
        style={{
          marginLeft: 'auto',
          width: '50%'
        }}
        freeSolo
        options={colormaps}
        renderInput={(params) =>
          <TextField
            {...params}
            label={colormap_text}
            size="small"
            margin="dense"
          />}
        onChange={(e, data) => handleChange(data)}
      />
    </ThemeProvider>
  )
}

const ColorDictBox = ({ colorDict, setColorDict }) => {
  const handleChange = (e) => {
    setColorDict(e.target.value);
  }
  return (<input
    style={{
      marginLeft: 'auto',
      width: '50%'
    }}
    type='text'
    value={decodeURIComponent(colorDict)}
    placeholder='custom colormap'
    onChange={handleChange}
  />
  )
}


const AddRasterLayerPanelButton = ({
  sourceParams, layerInfo, layers, setLayers, label }) => {
  // update if label is 'update'
  const map = useContext(MapContext);
  const catalog = useContext(CatalogContext);
  const dsn = catalog.id;

  const handleAddClick = () => {
    const id = uuidv4();
    const layerInfo = {
      ...layerDefaults,
      id: id,
      sourceId: `s-raster-${id}`,
      layerId: `l-raster-${id}`,
      dsn: dsn,
      sourceParams: sourceParams
    };
    addRasterLayer(map.current, layerInfo);
    setLayers([...layers, layerInfo]);
  }
  const handleUpdateClick = () => {
    const newLayerInfo = {
      ...layerInfo,
      sourceParams: sourceParams
    };
    addRasterLayer(map.current, newLayerInfo);
    const newLayers = layers.map((layer) => {
      if (layer.id === layerInfo.id) {
        return newLayerInfo
      } else {
        return layer
      }
    })
    setLayers(newLayers);
  }

  return (<input
    style={{
      width: '30%'
    }}
    type='button'
    value={label}
    onClick={label == 'add' ? handleAddClick : handleUpdateClick}
  />
  )
}

const ColorLegend = ({ colorDict }) => {
  let cdict = {}
  try {
    const ctext = decodeURIComponent(colorDict);
    var o = JSON.parse(ctext);
    if (o  && typeof o === "object") {
      cdict = JSON.parse(decodeURIComponent(colorDict));
    }
  } catch (e) {
    console.log("not valid json")
  }
  const boxStyle = {
    float: 'left',
    width: '20px',
    marginRight: '10px',
    border: '0px solid black',
    clear: 'both',
  }

  return (
    <div style={{
      display: 'grid', gridTemplateColumns: '90px 90px 90px'
    }}> {
        Object.keys(cdict).map((key)=> {
          const colorStyle = {
            ...boxStyle,
            backgroundColor: cdict[key],
            margin: 4,
            border: '2px solid white',
            verticalAlign: 'middle'
          };
          console.log(cdict[key]);
          return (
            <div key={key}>
              <div style={colorStyle}> &nbsp; &nbsp; </div>
              <div style={{ marginTop: 5 }}>f{parseInt(key)}</div>
            </div>)
        })
      }</div >
  )
}

const generateFullColorExpression = (colorDef) => {
  const rExp = colorDef.map((topic) => {
    return `${topic['R']}%2Ab${parseInt(topic.Name) + 1}`
  }).join('%2B');
  const gExp = colorDef.map((topic) => {
    return `${topic['G']}%2Ab${parseInt(topic.Name) + 1}`
  }).join('%2B');
  const bExp = colorDef.map((topic) => {
    return `${topic['B']}%2Ab${parseInt(topic.Name) + 1}`
  }).join('%2B');
  const exp = `${rExp};${gExp};${bExp}`;
  console.log(exp);
  return exp
}

const generateColorMap = (colorDef) => {
  const topicHexCodes = {};
  colorDef.forEach((topic) => {
    const hexCode = convertRGBToHex(topic.R, topic.G, topic.B);
    topicHexCodes[topic.Name] = hexCode;
  });
  const exp = JSON.stringify(topicHexCodes);
  console.log(exp);
  return exp
}

const convertRGBToHex = (r, g, b) => {
  const toHex = (c) => {
    const hex = Math.round(c * 255).toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  };
  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
}

export const convertFactorText2Expression = (factorText) => {
  const regex = /f(\d+)/g;
  const expression = factorText.replace(regex, (_, num) => "b" + (parseInt(num) + 1));
  return expression;
}
export const convertExpression2FactorText = (expression) => {
  const regex = /b(\d+)/g;
  const fText = expression.replace(regex, (_, num) => "f" + (parseInt(num) - 1));
  return fText;
}


export default RasterSourceControl;
