import { Protocol } from "pmtiles";
import * as pmtiles from "pmtiles";

import datalist from '../datalist.json';
import { ThemeProvider, createTheme } from '@mui/material/styles';

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
  typography: {
    fontSize: 11,
  },
});


export const colorsTab10 = {
  0: '#1f77b4',
  1: '#ff7f0e',
  2: '#2ca02c',
  3: '#d62728',
  4: '#9467bd',
  5: '#8c564b',
  6: '#e377c2',
  7: '#7f7f7f',
  8: '#bcbd22',
  9: '#17becf',
  10: '#f5b3ae',
  11: '#a5dea4'
};

export const colorsTurboArray = [
  0.0, '#30123b',
  0.071, '#4145ab',
  0.143, '#4675ed',
  0.214, '#39a2fc',
  0.286, '#1bcfd4',
  0.357, '#24eca6',
  0.429, '#61fc6c',
  0.5, '#a4fc3b',
  0.571, '#d1e834',
  0.643, '#f3c63a',
  0.714, '#fe9b2d',
  0.786, '#f36315',
  0.857, '#d93806',
  0.929, '#b11901',
  1.0, '#7a0402'
];


export const circularArray = (i, n, r) => {
  // i : ith element
  // n : number of total elements
  // radius 
  const x = Math.cos(i * Math.PI / n);
  const y = Math.sin(i * Math.PI / n);
  return [x, y]
}

export const obj2flatArray = (obj) => {
  const res = Object.keys(obj).map(
    (key) => [Number(key), obj[key]]).flat();
  return res
};

export const isUserAllowed = (dataset, user) => {
  let usergroups = [];
  let isUserAllowed = false;
  if (!user) {
    isUserAllowed = false;
  } else {
    usergroups = user.signInUserSession.idToken.payload["cognito:groups"]
  }
  if (usergroups.indexOf("powerusers") >= 0) {
    isUserAllowed = true;
  }
  if (user && dataset.hasOwnProperty('isSample') && dataset.isSample) {
    isUserAllowed = true;
  }
  usergroups.forEach((usergroup) => {
    if (dataset.id === usergroup) {
      isUserAllowed = true;
    }
  });
  return isUserAllowed;
}

export const isSampleDataset = (dsn) => {
  let isSample = false;
  datalist.map((d) => {
    if (d.id === dsn) {
      if (d.isSample) {
        isSample = isSample || true;
      }
    }
  })
  return isSample
}

export const getDatasetInfo = (dsn, datalist) => {
  datalist.map((d) => {
    if (d.id === dsn) {
      console.log(d);
      return d;
    };
  })
}

export const pmtile_metadata = async (url) => {
  let protocol = new Protocol();
  const file = new pmtiles.PMTiles(url);
  protocol.add(file);
  const meta = await file.getMetadata();
  return meta  
}
