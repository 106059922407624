// a panel UI for SGE layer opacity, radius 
import { useState } from 'react';
import { PointLayerPanel } from '../CommonUI/Panel.js';

export const SgeLayerPanel = ({ map, layerInfo, removeLayer }) => {
  const [state, setState] = useState({
    ...layerInfo,
    layerName: layerInfo.geneList.slice(0,1),
  });

  return (
    <div>
      <PointLayerPanel
        state={state}
        setState={setState}
        removeLayer={removeLayer}>
      </PointLayerPanel>
    </div >)
}


export default SgeLayerPanel;
