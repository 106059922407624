import { useState } from 'react';

export const CollapsiblePanel = ({ heading, initActive, children }) => {
  const [state, setState] = useState({
    isPanelActive: initActive,
  });

  const hidePanel = () => {
    setState({ ...state, isPanelActive: !state.isPanelActive })
  }

  return (<div>
    <h3 onClick={hidePanel}>{heading}</h3>
    <div
      style={{ display: state.isPanelActive ? 'block' : 'none' }}
    >
      {children}
    </div>
  </div>)
}


export const LayerPanel = ({ state, setState, removeLayer, children }) => {
  // state must have
  // id, map,layerId, sourceId, isDisplayed, layerName 
  const [toggle, setToggle] = useState(true)

  const handleDeleteClick = () => {
    const map = 'current' in state.map ?
      state.map.current : state.map;
    if (map.getLayer(state.layerId)) {
      map.removeLayer(state.layerId);
      map.removeSource(state.sourceId);
    }
    removeLayer(state.id);
  }
  const handleHideClick = (e) => {
    const value = e.target.value;
    const map = 'current' in state.map ?
      state.map.current : state.map;
    map.setLayoutProperty(
      state.layerId, 'visibility',
      value === 'show' ? 'visible' : 'none'
    );
    setState({ ...state, isDisplayed: !state.isDisplayed })
  }
  const handleNameClick = (e) => {
    const value = e.target.value;
    console.log(value);
    setToggle(false);
  }
  const handleNameChange = (e) => {
    const value = e.target.value;
    setState({ ...state, layerName: value });
  }
  const handleNameKeyDown = (e) => {
    const value = e.target.value;
    if (e.key === 'Enter' || e.key === 'Escape') {
      setToggle(true)
      e.preventDefault()
      e.stopPropagation()
    }
  }

  return (
    <div style={{
      border: '1px solid #8080A6',
      borderRadius: '10px',
      padding: '10px',
      margin: '10px'
    }}>
      <div
        style={{
          float: 'left',
          color: '#898989',
          marginRight: '5px'
        }}>
        #{state.id.slice(0, 4)}
      </div>
      {toggle ? (
        <b
          onDoubleClick={handleNameClick}
        >{state.layerName}</b>
      ) : (
        <input
          type='text'
          value={state.layerName}
          onChange={handleNameChange}
          onKeyDown={handleNameKeyDown}
          onFocus={e => e.target.select()}
        />
      )}
      <input
        style={{ float: 'right' }}
        type="button"
        component="span"
        value="x"
        onClick={handleDeleteClick}
      />
      <input
        style={{ float: 'right' }}
        type="button"
        component="span"
        value={state.isDisplayed ? "hide" : "show"}
        onClick={handleHideClick}
      />
      <hr />
      <div className={state.isDisplayed ? "" : "disable"} >
        {children}
      </div>
    </div>)
}

export const PointLayerPanel = ({ state, setState, removeLayer, children }) => {

  const handleOpacityChange = (e) => {
    const value = Number(e.target.value);
    state.map.setPaintProperty(
      state.layerId, 'circle-opacity', value);
    setState({ ...state, opacity: value, });
  }
  const handleRadiusChange = (e) => {
    const value = Number(e.target.value);
    state.map.setPaintProperty(
      state.layerId, 'circle-radius',
      [
        "interpolate",
        ["exponential", 2],
        ["zoom"],
        0, 0,
        21, ['*', 27, value ]
      ],
    );
    setState({ ...state, radius: value, });
  }
  const handleColorChange = (e) => {
    const value = e.target.value;
    state.map.setPaintProperty(
      state.layerId, 'circle-color', value);
    setState({ ...state, color: value, });
  };

  return (
    <div>
      <LayerPanel state={state} setState={setState} removeLayer={removeLayer}>
        <div className={state.isDisplayed ? "" : "disable"} >
          {children}
          <hr style={{ borderBottom: "0px" }} />
          <div>opacity: {state.opacity}
            <input
              onChange={handleOpacityChange}
              name="factorOpacity"
              min="0"
              max="1"
              value={state.opacity}
              step=".05"
              type="range"
            />
          </div>
          <div>radius: {state.radius}
            <input
              onChange={handleRadiusChange}
              name="factorRadius"
              min="0"
              max={state.radiusMax || 100}
              value={state.radius}
              step={state.radiusStep || 1}
              type="range"
            />
          </div>
          <div>
            <label>
              color
              <input
                type="color"
                value={state.color}
                onChange={handleColorChange}
              />
            </label>
          </div>
        </div>
      </LayerPanel>
    </div>
  )
}

