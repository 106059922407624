// top level panel for SGE display
import { useState } from 'react';

import { CollapsiblePanel } from '../CommonUI/Panel.js';
import { RasterLayerPanel } from './RasterLayerPanel';
import { RasterSourceControl } from './RasterSourceControl';

const RasterControlPanel = ({ }) => {


  const [layers, setLayers] = useState([]);

  const removeLayer = (id) => {
    // remove layer from currentLayers
    const updatedLayers = layers.filter(l => l.id !== id);
    setLayers(updatedLayers);
  }

  return (<div>
    <CollapsiblePanel
      heading='Raster'
      initActive={false}>
      <div style={{ color: '#BBB' }} >
        Display raster datasets
        <RasterSourceControl
          layerInfo={null}
          layers={layers} setLayers={setLayers}
          buttonLabel="add" />
      </div>
      <div style={{
        flexDirection: 'column-reverse'
      }}
      >
        {layers.map((layerInfo) => {
          return (
            <div key={layerInfo.id}>
              <RasterLayerPanel
                layerInfo={layerInfo}
                removeLayer={removeLayer}
                layers={layers} setLayers={setLayers}
              />
            </div>)
        })}
      </div>
    </CollapsiblePanel>
  </div >)
}
export default RasterControlPanel;
