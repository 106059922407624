// a UI for investigate raster pixel information 
import { useState, useContext, useEffect, useRef } from 'react';
import { MapContext } from '../MapContext';
import ColorizeIcon from '@mui/icons-material/Colorize';
import { toMercator } from '@turf/projection';
import { point } from '@turf/helpers';

import { convertExpression2FactorText, convertFactorText2Expression } from './RasterSourceControl';

export const RasterEyeDropper = ({ sourceParams }) => {
  // endpoint like this 
  // https://ou60yuvp0i.execute-api.us-east-1.amazonaws.com/cog/point/2706,1259?url=https%3A%2F%2Fcartoscope-xenium.s3.amazonaws.com%2Fraster%2Ffactor%2FnF30.d_12.cog.tif&coord-crs=epsg%3A3857&expression=b1%3Bb2%3Bb1%2Bb2&nodata=99&unscale=false
  // response:
  // {"coordinates":[2706.0,1259.0],"values":[1.2699999920089563e-19,99.0,99.0],"band_names":["b1","b2","b1+b2"]}

  const map = useContext(MapContext);
  const [isPicking, setIsPicking] = useState(false);
  const [expression, setExpression] = useState('b1');
  const [point, setPoint] = useState([0, 0]);
  const [pixelInfo, setPixelInfo] = useState({});
  const clickHandlerRef = useRef(null);

  const handleClick = () => {
    setIsPicking((prevIsPicking) => !prevIsPicking);
  };

  const fetchPixelInfo = async (point, sourceParams) => {
    const endpoint = constructRequest(point, sourceParams['url'], expression);
    const res = await fetch(endpoint);
    const data = await res.json();
    setPixelInfo(data);
  }

  useEffect(() => {
    if (map.current) {
      const clickHandler = (e) => {
        if (isPicking) {
          const [x, y] = lngLat2mercator(e.lngLat);
          setPoint([x, y]);
          fetchPixelInfo([x, y], sourceParams);
        }
      };
      map.current.on('click', clickHandler);
      clickHandlerRef.current = clickHandler;

      if (isPicking) {
        map.current.getCanvas().style.cursor = 'crosshair';
      } else {
        map.current.getCanvas().style.cursor = '';
      }
    }
    return () => {
      if (map.current) {
        map.current.off('click', clickHandlerRef.current); // Remove the click event listener when unmounted
      }
    };
  }, [isPicking, expression]);

  return (
    <div>
      <hr />
      <ColorizeIcon
        size="small" color={isPicking ? "active" : "disabled"}
        // className={isPicking ? 'fade' : ''}
        onClick={handleClick}
      />
      {isPicking ? "Select a point" : "View Pixel Info"}
      <div className={isPicking ? '' : 'disable'} >
        <FactorBox
          expression={sourceParams.expression}
          setExpression={setExpression} />
        <div> x: {point[0].toFixed(2)} y: {point[1].toFixed(2)} </div>
        <div> Value: {pixelInfo?.values?.map((v) => v.toFixed(4)).join(';')} </div>
      </div>
    </div>
  )
}

const FactorBox = ({ expression, setExpression }) => {
  const decodedExpression = decodeURIComponent(expression);
  const fText = convertExpression2FactorText(decodedExpression);
  const [factorText, setFactorText] = useState(fText);

  const handleChange = (e) => {
    const fText = e.target.value;
    setFactorText(fText);
    setExpression(convertFactorText2Expression(fText));
  }
  return (
    <div>
      <div> Factor:
        <input
          style={{
            marginLeft: 'auto',
            width: '50%'
          }}
          type='text'
          value={factorText}
          placeholder='pick a factor such as f0'
          onChange={handleChange}
        />
      </div>
    </div>
  )
}

const lngLat2mercator = (lngLat) => {
  const ptWgs84 = point([lngLat['lng'], lngLat['lat']]);
  const ptMerc = toMercator(ptWgs84);
  // [0] and [1]
  return ptMerc['geometry']['coordinates'];
}

const constructRequest = (point, url, expression) => {
  const endpointUrl = 'https://ou60yuvp0i.execute-api.us-east-1.amazonaws.com/cog/point';
  const params = {
    'url': encodeURIComponent(url),
    'coord-crs': encodeURIComponent("epsg:3857"),
    'expression': encodeURIComponent(expression)
  }
  const url_params = Object.keys(params).map(i => `${i}=${params[i]}`).join('&');
  const endpoint = `${endpointUrl}/${point[0]},${point[1]}?${url_params}`;
  return endpoint
}



export default RasterEyeDropper;
