// top level panel for SGE display
import { useState, useMemo, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { CollapsiblePanel } from '../CommonUI/Panel.js';
import { addSgeLayer, layerDefaults } from './LayerControl';
import { SgeLayerPanel } from './SgeLayerPanel';

import { CatalogContext } from '../CatalogContext';

const SgeFgbControlPanel = ({ map, dsn, sgeTilePaths }) => {

  const [state, setState] = useState({
    textInput: '',
    currentLayers: []
  });

  const [geneListText, setGeneListText] = useState('');
  const geneList = useMemo(() => geneListText.split(",").map((item) => item.trim()), [geneListText]);
  const [layers, setLayers] = useState([]);

  const removeLayer = (id) => {
    // remove layer from currentLayers
    const updatedLayers = layers.filter(l => l.id !== id);
    setLayers(updatedLayers);
  }
  let disclaimer = '';
  if (layers.length > 0) {
    if (map.current) {
      if (map.current.getZoom() > 12) {
        disclaimer = (
          <p style={{ color: 'gray' }}>
            Rendering dense gene expressions at lower zoom level may take longer time.
          </p>)
      }
    }
  }

  return (<div>
    <CollapsiblePanel
      heading='Gene Expressions'
      initActive={false}>
      <div
        style={{
          width: '100%',
          color: '#BBB'
        }}
      >
        Enter the name of a single gene you want to display (case-sensitive)
        <SgeTextBox geneListText={geneListText} setGeneListText={setGeneListText} />
        <AddSgeLayerPanelButton
          map={map} dsn={dsn} geneList={geneList}
          layers={layers} setLayers={setLayers}
          sgeTilePaths={sgeTilePaths}
        />
      </div>
      <div style={{
        flexDirection: 'column-reverse'
      }}
      >
        {layers.map((layerInfo) => {
          return (
            <div key={layerInfo.id}>
              <SgeLayerPanel
                map={map}
                layerInfo={layerInfo}
                removeLayer={removeLayer}
              />
            </div>)
        })}
        {disclaimer}
      </div>
    </CollapsiblePanel>
  </div >)
}

const SgeTextBox = ({ geneListText, setGeneListText }) => {
  // const [state, setState] = useState(geneListText);
  const handleChange = (e) => {
    setGeneListText(e.target.value);
  }
  return (<input
    style={{
      float: 'left',
      width: '80%'
    }}
    type='text'
    value={geneListText}
    placeholder='GeneSymbol'
    onChange={handleChange}
  />
  )
}

const AddSgeLayerPanelButton = ({ map, dsn, geneList, layers, setLayers, sgeTilePaths }) => {
  const catalog = useContext(CatalogContext);
  const handleClick = () => {
    const id = uuidv4();
    const layerInfo = {
      ...layerDefaults,
      id: id,
      map: map.current,
      dsn: dsn,
      folder: (catalog?.assets?.sge?.perGene) ?  catalog.assets.sge.perGene: "tile/pmtiles/", 
      sourceId: `s-sge-${id}`,
      layerId: `l-sge-${id}`,
      geneList: geneList
    };
    addSgeLayer(map.current, layerInfo, sgeTilePaths);
    setLayers([...layers, layerInfo]);
  }
  return (<div>
    <input
      type='button'
      value="add"
      onClick={handleClick}
    />
  </div>)
}
export default SgeFgbControlPanel;
