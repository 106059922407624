import React, { useState, useRef } from 'react';
import { Button } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

export const RegionCreateButton = ({ draw, onRegionCreate }) => {
  
  const handleClick = (e) => {
    console.log(e); 
    if ( draw.current && draw.current.getAll() && draw.current.getAll().features ) {
      const features = draw.current.getAll().features;
      const multipolygon = convertToMultiPolygon(features);
      console.log(multipolygon);
      onRegionCreate(multipolygon);
      draw.current.deleteAll();
    } else {
      console.log("no features")
    }
  };

  return (
    <Tooltip title="Create ROI from current drawing">
      <Button variant="contained" 
        color="info" size="small" 
        onClick={handleClick} style={{ margin: 5 }}>
        Create 
      </Button>
    </Tooltip>
  );
}
function convertToMultiPolygon(features) {
  const polygons = [];
  let firstGeometryId = null;
  features.forEach((feature, index) => {
    console.log(feature);
    if (feature.geometry.type === 'Polygon') {
      polygons.push(feature.geometry.coordinates);
      if (index === 0) {
        firstGeometryId = feature.id;
      }
    }
  });
  const multipolygon = {
    type: 'MultiPolygon',
    coordinates: polygons
  };
  return {
    type: 'Feature',
    geometry: multipolygon,
    id: firstGeometryId
  };
}

export default RegionCreateButton;
