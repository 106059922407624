import React, { useState } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Grid from '@mui/material/Grid';

import Button from '@mui/material/Button';
import DataSelector from './DataSelector'
import RoiGeneDrawButton from './RoiGeneDrawButton';

import { calculateOddsRatioIncluded, calculateOddsRatioDisjoint } from './Data';

export const GeneGrid = ({ map, rois }) => {

  const [tableData, setTableData] = useState([]);
  const [currentTarget, setCurrentTarget] = useState('');
  const [currentComparison, setCurrentComparison] = useState('WholeLane');

  const handleORClick = () => {
    console.log(rois);
    let targetRoi = {};
    let comparisonRoi = {};
    rois.forEach((roi) => {
      if (roi.id === currentTarget) {
        targetRoi = roi;
      };
      if (roi.id === currentComparison) {
        comparisonRoi = roi;
      };
    });
    const tableData = comparisonRoi.id == 'WholeLane' ?
      calculateOddsRatioIncluded(targetRoi, comparisonRoi) :
      calculateOddsRatioDisjoint(targetRoi, comparisonRoi);
    setTableData(tableData);
  }

  const renderViewButton = (params) => {
    const targetRoi = rois.find(roi => roi.id === currentTarget);
    return (
      <RoiGeneDrawButton
        roi={targetRoi}
        param={params.row}
        map={map}
      />
    )
  }
  // ROI table column
  const columns_main = [
    { field: 'gene_name', headerName: 'Gene', flex: 1.5, disableClickEventBubbling: true, renderCell: renderViewButton },
    { field: 'targetCount', headerName: 'Target Cnt', flex: 1, type: 'number', disableClickEventBubbling: true, },
    {
      field: 'targetFreq', headerName: 'Target Freq',
      flex: 1, type: 'number', disableClickEventBubbling: true,
      valueFormatter: ({ value }) => value.toExponential(3),
      hide: true
    },
    { field: 'targetTotal', headerName: 'Target Total', flex: 1, type: 'number', disableClickEventBubbling: true, hide: true },

    { field: 'OR', headerName: 'OR', flex: 1, type: 'number', disableClickEventBubbling: true },
    // { field: 'Z', headerName: 'Z', flex: 1, type: 'number', disableClickEventBubbling: true },
    { field: 'pvalue', headerName: 'p-value', flex: 1, type: 'number', disableClickEventBubbling: true },
    { field: 'comparisonCount', headerName: 'Comp. Cnt', flex: 1, type: 'number', disableClickEventBubbling: true },
    {
      field: 'comparisonFreq', headerName: 'Comp. Freq',
      flex: 1, type: 'number', disableClickEventBubbling: true,
      valueFormatter: ({ value }) => value.toExponential(3),
      hide: true
    },
    { field: 'comparisonTotal', headerName: 'Comp. Total', flex: 1, type: 'number', disableClickEventBubbling: true, hide: true },
  ];

  return (
    <Grid container>
      <Grid item xs={3}>
        <DataSelector
          roiIds={rois.map((roi) => { return roi.id })}
          label={'Target'}
          setCurrentRoi={setCurrentTarget}
          defaultRoi={rois[0] ? rois[0].id : ""}
        />
      </Grid>
      <Grid item xs={3} align="center" >
        <Button
          variant="contained"
          onClick={handleORClick}
        > Calc Odds Ratio </Button>
      </Grid>
      <Grid item xs={3}>
        <DataSelector
          roiIds={rois.map((roi) => { return roi.id })}
          label={'Comparison'}
          setCurrentRoi={setCurrentComparison}
          defaultRoi={rois[0] ? rois[0].id : ""}
        />
      </Grid>
      <Grid item xs={12}>
        <DataGrid
          initialState={{
            pagination: { paginationModel: { pageSize: 50 } },
          }}
          disableRowSelectionOnClick
          rows={tableData}
          columns={columns_main}
          hideFooter={false}
          autoHeight={true}
          density='compact'
          components={{
            Toolbar: GridToolbar,
          }}
        />
      </Grid>
    </Grid>
  )
}
