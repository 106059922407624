import React from "react";
import { colorsTab10 } from "./Util";
import { DataGrid } from '@mui/x-data-grid';

const Tooltip = ({ feature }) => {
  const { id } = feature.properties;
  const data = Object.keys(feature.properties).map((key) => 
        ({ 'key': key, 'value': feature.properties[key]})
  );
  const columns = [
    { field: 'key', headerName: 'key', width:120},
    { field: 'value', headerName: 'value', width:120}
  ];

  return (
    <div id={`tooltip-${id}`} className='tooltip' style={{width:'200px'}} >
      <DataGrid
        disableRowSelectionOnClick
        initialState={{
          pagination: { paginationModel: { pageSize: 15 } },
          sorting: {
            sortModel: [{ field: 'key', sort: 'asc' }],
          },
        }}
        rows={data}
        columns={columns}
        getRowId={(row) => row.key}
        rowHeight={20}
        autoHeight={true}
        hideFooter={false}
        density='compact'
      />
    </div>
  );
};

export default Tooltip;
