import { useState, useContext, useEffect } from 'react';
import { MapContext } from '../MapContext';

export const RasterStyleControl = ({ layerInfo, setLayerInfo }) => {
  const map = useContext(MapContext);
  const [styleParams, setStyleParams] = useState(
    layerInfo.styleParams
  );

  const handleOpacityChange = (e) => {
    const value = Number(e.target.value);
    map.current.setPaintProperty(
      layerInfo.layerId, 'raster-opacity', value);
    setStyleParams({ ...styleParams, 'raster-opacity': value, });
  }
  const handleContrastChange = (e) => {
    const value = Number(e.target.value);
    map.current.setPaintProperty(
      layerInfo.layerId, 'raster-contrast', value);
    setStyleParams({ ...styleParams, 'raster-contrast': value, });
  }
  const handleBrightnessMinChange = (e) => {
    const value = Number(e.target.value);
    map.current.setPaintProperty(
      layerInfo.layerId, 'raster-brightness-min', value);
    setStyleParams({ ...styleParams, 'raster-brightness-min': value, });
  }
  const handleBrightnessMaxChange = (e) => {
    const value = Number(e.target.value);
    map.current.setPaintProperty(
      layerInfo.layerId, 'raster-brightness-max', value);
    setStyleParams({ ...styleParams, 'raster-brightness-max': value, });
  }
  useEffect(() => {
    setLayerInfo({
      ...layerInfo,
      styleParams: styleParams
    })
  }, [styleParams]);

  return (
    <div>
      <div
        style={{ display: 'flex' }}
      >opacity: {styleParams['raster-opacity']}
        <input
          onChange={handleOpacityChange}
          name="rasterOpacity"
          min="0"
          max="1"
          value={styleParams['raster-opacity']}
          step=".05"
          type="range"
          style={{ marginLeft: 'auto', width: '40%' }}
        />
      </div>
      <div
        style={{ display: 'flex' }}
      >contrast: {styleParams['raster-contrast']}
        <input
          onChange={handleContrastChange}
          name="rasterContrast"
          min="0"
          max=".95"
          value={styleParams['raster-contrast']}
          step=".05"
          type="range"
          style={{ marginLeft: 'auto', width: '40%' }}
        />
      </div>
      <div
        style={{ display: 'flex' }}
      >bright-min: {styleParams['raster-brightness-min']}
        <input
          onChange={handleBrightnessMinChange}
          name="rasterBrMin"
          min="0"
          max="1"
          value={styleParams['raster-brightness-min']}
          step=".05"
          type="range"
          style={{ marginLeft: 'auto', width: '40%' }}
        />
      </div>
      <div
        style={{ display: 'flex' }}
      >bright-max: {styleParams['raster-brightness-max']}
        <input
          onChange={handleBrightnessMaxChange}
          name="rasterBrMax"
          min="0"
          max="1"
          value={styleParams['raster-brightness-max']}
          step=".05"
          type="range"
          style={{ marginLeft: 'auto', width: '40%' }}
        />
      </div>
    </div>)
}
export default RasterStyleControl;
