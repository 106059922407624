import { useState } from 'react';
import { LayerPanel } from '../CommonUI/Panel.js';
import { v4 as uuidv4 } from 'uuid';
import { bgLayerDefaults } from '../Background/LayerControl';
export const BgLayerPanel = ({ map, index, layerInfo, removeLayer }) => {

  const [state, setState] = useState({
    ...layerInfo,
    map: map,
    id: uuidv4(),
    layerId: `l-basemap-${index}`,
    sourceId: `basemap-${index}`,
    layerName: layerInfo.label,
    opacity: bgLayerDefaults.opacity,
    brightnessMin: bgLayerDefaults.brightnessMin,
    brightnessMax: bgLayerDefaults.brightnessMax,
    contrast: bgLayerDefaults.contrast,
    hueRotate: bgLayerDefaults.hueRotate
  });

  const handleOpacityChange = (e) => {
    const value = Number(e.target.value);
    state.map.current.setPaintProperty(
      state.layerId, 'raster-opacity', value);
    setState({ ...state, opacity: value, });
  }
  const handleContrastChange = (e) => {
    const value = Number(e.target.value);
    state.map.current.setPaintProperty(
      state.layerId, 'raster-contrast', value);
    setState({ ...state, contrast: value, });
  }
  const handleBrightnessMinChange = (e) => {
    const value = Number(e.target.value);
    state.map.current.setPaintProperty(
      state.layerId, 'raster-brightness-min', value);
    setState({ ...state, brightnessMin: value, });
  }
  const handleBrightnessMaxChange = (e) => {
    const value = Number(e.target.value);
    state.map.current.setPaintProperty(
      state.layerId, 'raster-brightness-max', value);
    setState({ ...state, brightnessMax: value, });
  }
  const handleHueRotateChange = (e) => {
    const value = Number(e.target.value);
    state.map.current.setPaintProperty(
      state.layerId, 'raster-hue-rotate', value);
    setState({ ...state, hueRotate: value, });
  }
  return (
    <div>
      <LayerPanel state={state} setState={setState} removeLayer={removeLayer}>
        <div
          style={{ display: 'flex' }}
        >opacity: {state.opacity}
          <input
            onChange={handleOpacityChange}
            name="rasterOpacity"
            min="0"
            max="1"
            value={state.opacity}
            step=".05"
            type="range"
            style={{ marginLeft: 'auto', width: '40%' }}
          />
        </div>
        <div
          style={{ display: 'flex' }}
        >contrast: {state.contrast}
          <input
            onChange={handleContrastChange}
            name="rasterContrast"
            min="0"
            max=".95"
            value={state.contrast}
            step=".05"
            type="range"
            style={{ marginLeft: 'auto', width: '40%' }}
          />
        </div>
        <div
          style={{ display: 'flex' }}
        >bright-min: {state.brightnessMin}
          <input
            onChange={handleBrightnessMinChange}
            name="rasterBrMin"
            min="0"
            max="1"
            value={state.brightnessMin}
            step=".05"
            type="range"
            style={{ marginLeft: 'auto', width: '40%' }}
          />
        </div>
        <div
          style={{ display: 'flex' }}
        >bright-max: {state.brightnessMax}
          <input
            onChange={handleBrightnessMaxChange}
            name="rasterBrMax"
            min="0"
            max="1"
            value={state.brightnessMax}
            step=".05"
            type="range"
            style={{ marginLeft: 'auto', width: '40%' }}
          />
        </div>
      </LayerPanel>
    </div>
  )
}

