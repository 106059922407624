// functions for add/remove layers and sources
import { Protocol } from "pmtiles";
import * as pmtiles from "pmtiles";

export const layerDefaults = {
  isDisplayed: true,
  opacity: 0.85,
  radius: 7,
  radiusStep: 1,
  radiusMax: 40,
  opacity: 0.85,
  color: '#F0D040'

};

export const addSgeLayer = (map, layerInfo, sgeTilePaths) => {
  // layer name convention
  // layer: l-sge-uuid
  // source: s-sge-uuid
  let geneName = layerInfo.geneList[0].replace("/", "／");
  let PMTILES_URL = `https://${layerInfo.dsn}.s3.amazonaws.com/${layerInfo.folder}${geneName}.pmtiles`;
  let protocol = new Protocol();
  const file = new pmtiles.PMTiles(PMTILES_URL);
  protocol.add(file);

  // get metadata
  const fetchmeta = async () => {
    const meta = await file.getMetadata();
    const data = await meta.tilestats;
    console.log(data);
  }
  fetchmeta();

  if (!map.getSource(layerInfo.sourceId)) {
    map.addSource(layerInfo.sourceId, {
      "type": "vector",
      "url": "pmtiles://" + PMTILES_URL
    });
  };
  map.addLayer({
    'id': layerInfo.layerId,
    'type': 'circle',
    'source': layerInfo.sourceId,
    'source-layer': `sge`,
    'paint': {
      'circle-color': layerInfo.color,
      'circle-opacity': layerDefaults.opacity,
      'circle-radius': [
        "interpolate",
        ["exponential", 2],
        ["zoom"],
        0, 0,
        21, ['*', 27, layerDefaults.radius]
      ],
    }
  });
}

export const deleteFactorLayer = (map, layerInfo) => {
  if (map.getLayer(layerInfo.layerId)) {
    map.removeLayer(layerInfo.layerId);
    map.removeSource(layerInfo.layerId);
  }
}
