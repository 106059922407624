// functions for add/remove layers and sources
import { Protocol } from "pmtiles";
import * as pmtiles from "pmtiles";

export const layerDefaults = {
  colorFactors: { 'R': 'Topic_0', 'G': 'Topic_1', 'B': 'Topic_2' },
  opacity: 0.85,
  radius: 4,
  radiusStep: 1,
  radiusMax: 16,
  isTopKChecked: false,
  isDisplayed: true
};


export const addFactorLayer = (map, layerInfo) => {
  // layer name convention
  // l-factor-datasetid
  if (layerInfo.dataset?.format == 'pmtiles') {
    const ds = layerInfo.dataset;
    const pmurl = `${ds.host}/${ds.tileDir}`;
    const protocol = new Protocol();
    const file = new pmtiles.PMTiles(pmurl);
    protocol.add(file);
    map.addSource(layerInfo.sourceId, {
      "type": "vector",
      "url": "pmtiles://" + pmurl 
    });
  } else {
    map.addSource(layerInfo.sourceId, {
      'type': 'vector',
      'tiles': [
        `${layerInfo.dataset.host}/${layerInfo.dataset.tileDir}{z}/{x}/{y}.pbf`
      ],
      'minzoom': 10,
      'maxzoom': 16
    });
  }
  map.addLayer({
    'id': layerInfo.layerId,
    'type': 'circle',
    'source': layerInfo.sourceId,
    'source-layer': layerInfo.dataset.metadata.id,
    'paint': {
      'circle-color': [
        'rgb',
        ["*", 255, ['get', layerDefaults.colorFactors.R]],
        ["*", 255, ['get', layerDefaults.colorFactors.G]],
        ["*", 255, ['get', layerDefaults.colorFactors.B]],
      ],
      // 'circle-color': '#993322',
      'circle-opacity': layerDefaults.opacity,
      'circle-radius': [
        "interpolate",
        ["exponential", 2],
        ["zoom"],
        0, 0,
        21, ['*', 27, layerDefaults.radius]
      ]
    }
  });
  console.log(layerInfo.layerId);
}

export const deleteFactorLayer = (map, layerInfo) => {
  if (map.getLayer(layerInfo.layerId)) {
    map.removeLayer(layerInfo.layerId);
    map.removeSource(layerInfo.sourceId);
  }
}
