// a panel UI for single facor layer's R,G,B mix, opacity, radius 
import { useState, useEffect } from 'react';
import {interpolateSinebow} from 'd3-scale-chromatic';
import { color } from 'd3-color';
import { layerDefaults, deleteFactorLayer } from './LayerControl';
import {
  colorsTab10,
  colorsTurboArray,
  obj2flatArray,
  darkTheme
} from "../Util";

export const FactorLayerPanel = ({ map, layerInfo, removeLayer, factorAlias }) => {

  const [state, setState] = useState({
    ...layerInfo.layerState,
    singleChannel: Object.keys(layerInfo.dataset.metadata.fields)[0],
    fieldType: 'continuous'
  });
  const [attributes, setAttributes] = useState([]);
 
  const fetchFields = async () => {
    let data;
    if (layerInfo.dataset?.format == 'pmtiles') {
      data = layerInfo.dataset.meta.tilestats.layers[0]['attributes'];   
    } else {
      const url = `${layerInfo.dataset.host}/${layerInfo.dataset.tileDir}metadata.json`;
      const res = await fetch(url);
      const rawData = await res.json();
      data = JSON.parse(rawData['json'])['tilestats']['layers'][0]['attributes'];
    }
    setAttributes(data);
    const fieldR = data[0]['attribute'];
    const fieldG = data[1]['attribute'];
    const fieldB = data[2]['attribute'];
    setState({ 
      ...state, 
      colorFactors: {
        'R': fieldR, 
        'G': fieldG, 
        'B': fieldB, 
      } 
    });
    map.setPaintProperty(
      layerInfo.layerId,
      'circle-color',
      [
        'rgb',
        ["*", 255, ['get', fieldR]],
        ["*", 255, ['get', fieldG]],
        ["*", 255, ['get', fieldB]],
      ]
    );
  }

  useEffect(() => {
    fetchFields();
  }, []);

  const handleDeleteClick = () => {
    deleteFactorLayer(map, layerInfo);
    removeLayer(layerInfo);
  }
  const handleHideClick = (e) => {
    const value = e.target.value;
    map.setLayoutProperty(
      layerInfo.layerId, 'visibility',
      value === 'show' ? 'visible' : 'none'
    );
    setState({ ...state, isDisplayed: !state.isDisplayed })
  }

  const handleColorFactorChange = (e) => {
    const { name, value } = e.target;
    map.setPaintProperty(
      layerInfo.layerId,
      'circle-color',
      [
        'rgb',
        ["*", 255, ['get', name == 'R' ? value : state.colorFactors.R]],
        ["*", 255, ['get', name == 'G' ? value : state.colorFactors.G]],
        ["*", 255, ['get', name == 'B' ? value : state.colorFactors.B]],
      ]
    );
    setState({ ...state, colorFactors: { ...state.colorFactors, [name]: value } });
  }
  const handleSingleChannelChange = (e) => {
    const { name, value } = e.target;
    updateSingleChannelColor(value, state.fieldType);
    setState({ ...state, singleChannel: value })
  }
  const handleFieldTypeChange = (e) => {
    const { name, value } = e.target;
    updateSingleChannelColor(state.singleChannel, value);
    setState({ ...state, fieldType: value })
  }

  const handleOpacityChange = (e) => {
    const value = Number(e.target.value);
    map.setPaintProperty(
      layerInfo.layerId, 'circle-opacity', value);
    setState({ ...state, opacity: value, });
  }
  const handleRadiusChange = (e) => {
    const value = Number(e.target.value);
    map.setPaintProperty(
      layerInfo.layerId, 'circle-radius',
      [
        "interpolate",
        ["exponential", 2],
        ["zoom"],
        0, 0,
        21, ['*', 27, value]
      ]
    );
    setState({ ...state, radius: value, });
  }
  const handleSingleChannelClick = (e) => {
    const checked = e.target.checked;
    console.log(state.singleChannel);
    if (checked) {
      updateSingleChannelColor(state.singleChannel, state.fieldType);
    } else {
      map.setPaintProperty(
        layerInfo.layerId,
        'circle-color',
        [
          'rgb',
          ["*", 255, ['get', state.colorFactors.R]],
          ["*", 255, ['get', state.colorFactors.G]],
          ["*", 255, ['get', state.colorFactors.B]],
        ]
      );
    }
    setState({ ...state, isSingleChannelChecked: checked })
  }
  
  const generateColorMap = (field) => {
    const attribute = attributes.find(att => att['attribute']==field);
    if ((attribute['type'] == 'number') && !(attribute['attribute'] == 'topK')) {
      return colorsTurboArray
    } else {
      const values = attribute['values'];
      let colormap = [];
      values.forEach((value, i) => {
        colormap.push(value);
        const fcolor = color(interpolateSinebow( i/ values.length)).formatHex();
        colormap.push(fcolor);
      });
      return colormap
    }
  }

  const updateSingleChannelColor = (field, fieldType) => {
    
    const categoricalColorMap = generateColorMap(field); 
    let colorScale = {
      'continuous': [
        'interpolate', ['linear'],
        ['get', field],
      ].concat(colorsTurboArray),
      'categorical': [
        'match', 
        ['get', field]
      ].concat(categoricalColorMap, ['#ccc']),
    };
    map.setPaintProperty(
      layerInfo.layerId,
      'circle-color',
      colorScale[fieldType]
    );
  }

  return (
    <div style={{
      border: '1px solid #8080A6',
      borderRadius: '10px',
      padding: '10px',
      margin: '10px'
    }}>
      #{layerInfo.id.slice(0, 4)} <b>{layerInfo.dataset.id}</b>
      <input
        style={{ float: 'right' }}
        type="button"
        component="span"
        value="x"
        onClick={handleDeleteClick}
      />
      <input
        style={{ float: 'right' }}
        type="button"
        component="span"
        value={state.isDisplayed ? "hide" : "show"}
        onClick={handleHideClick}
      />
      <hr />
      <div
        className={state.isDisplayed ? "" : "disable"}
      >
        <div
          style={{ display: 'block' }}
          className={state.isTopKChecked || state.isSingleChannelChecked ? "disable" : ""}
        >
          {
            Object.keys(state.colorFactors).map(key => {
              return (
                <div> {key} channel
                  <select
                    name={key}
                    value={state.colorFactors[key]}
                    onChange={handleColorFactorChange}
                  >
                    {
                      attributes.map(f => {
                        const key = f['attribute'];
                        if (f['type'] == 'number') {
                          return (
                            <option key={key} value={key}>
                              {key.replace("Topic_", "") in factorAlias? 
                                factorAlias[key.replace("Topic_", "")] :
                                key
                              }
                            </option>
                          )
                        }
                      })
                    }
                  </select>
                </div>
              )
            })
          }
        </div>
        <hr style={{ borderBottom: "0px" }} />
        <div
          className={state.isTopKChecked ? "disable" : ""}
        >
          <label style={{ display: 'inline-block' }}>
            Single Channel View
            <input
              type="checkbox"
              onChange={handleSingleChannelClick}
              checked={state.isSingleChannelChecked}
            />
          </label>
          <label style={{ display: 'inline-block' }}>
            Field
            <select
              value={state.categorical}
              onChange={handleSingleChannelChange}
            >
              {
                attributes.map(f => {
                  const key = f['attribute'];
                  return (
                    <option key={key} value={key}>
                      {key.replace("Topic_", "") in factorAlias? 
                        factorAlias[key.replace("Topic_", "")] :
                        key
                      }
                    </option>
                  )
                })
              }
            </select>
          </label>
          <label style={{ display: 'inline-block' }}>
            Field Type {state.fieldType}
            <div>
              <input
                type="radio"
                value="categorical"
                checked={state.fieldType === "categorical"}
                onChange={handleFieldTypeChange}
              /> categorical
              <input
                type="radio"
                value="continuous"
                checked={state.fieldType === "continuous"}
                onChange={handleFieldTypeChange}
              /> continuous
            </div>
          </label>
        </div>
        <hr style={{ borderBottom: "0px" }} />
        <div>opacity: {state.opacity}
          <input
            onChange={handleOpacityChange}
            name="factorOpacity"
            min="0"
            max="1"
            value={state.opacity}
            step=".1"
            type="range"
          />
        </div>
        <div>radius: {state.radius}
          <input
            onChange={handleRadiusChange}
            name="factorRadius"
            min="0"
            max={state.radiusMax || 100}
            value={state.radius}
            step={state.radiusStep || 1}
            type="range"
          />
        </div>
      </div>
    </div>
  )
}

export default FactorLayerPanel;
