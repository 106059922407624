import React, { useEffect, useState } from 'react';
import axios from "axios";

import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import { useTheme } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import TableViewIcon from '@mui/icons-material/TableView';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { DataGrid, GridToolbar, GridColTypeDef } from '@mui/x-data-grid';

import { load as yamlload } from 'js-yaml';
import { VolcanoPlot } from "./VolcanoPlot";

const FactorView = ({ map, dsn }) => {
  const theme = useTheme();
  const [isMapMounted, setIsMapMounted] = useState(false);
  const [open, setOpen] = useState(false);
  const [datasets, setDatasets] = useState([]);
  const [currentDataset, setCurrentDataset] = useState({});
  const [factorInfo, setFactorInfo] = useState({});
  const [volcanoData, setVolcanoData] = useState({});
  const [factorNumber, setFactorNumber] = useState('');
  const [factorAliases, setFactorAliases] = useState({});


  const fetchFactorAliases = async () => {
    const url = `https://${dsn}.s3.amazonaws.com/catalog/factor-alias.yaml`;
    try {
      const res = await axios.get(url);
      const data = await res.data;
      const config = await yamlload(data);
      if (
        currentDataset &&
        'properties' in config &&
        currentDataset.id in config.properties['factor:id_to_alias']
      ) {
        setFactorAliases(config.properties['factor:id_to_alias'][currentDataset.id]);
      } else {
        setFactorAliases({})
      }
    } catch (e) {
      console.log("No Factor Alias");
      setFactorAliases({})
    }
  }
  useEffect(() => {
    fetchFactorAliases();
  }, [open, currentDataset]);

  const fetchDatasetList = async () => {
    const url = `https://htsz8cbyj8.execute-api.us-east-1.amazonaws.com/dev/lists3?bucket=${dsn}`;
    const res = await fetch(url);
    const data = await res.json();
    if (Array.isArray(data)) {
      setDatasets(data)
    }
  }
  const fetchFactorInfo = async (factorDsn) => {
    const url = `https://${dsn}.s3.amazonaws.com/vector/factor/${factorDsn}.json`;
    const res = await fetch(url);
    const rawData = await res.json();
    let data = rawData;
    if ('is_seurat' in rawData) {
      data = rawData['top_genes']
    }
    setFactorInfo(data)
    setFactorNumber(Object.keys(data)[0]);
  }
  useEffect(() => {
    fetchFactorInfo(
      currentDataset && 'id' in currentDataset ? 
      currentDataset.id : '');
  }, [currentDataset]);

  const fetchVolcanoData = async (factorDsn) => {
    const url = `https://${dsn}.s3.amazonaws.com/vector/factor/${factorDsn}-volcano.json`;
    const res = await fetch(url);
    const rawData = await res.json();
    let data = rawData;
    if ('is_seurat' in rawData) {
      data = rawData['volcano']
    }
    setVolcanoData(data);
  }

  useEffect(() => {
    fetchDatasetList();
  }, [open]);

  useEffect(() => {
    if (map.current) {
      return;
    } else {
      setIsMapMounted(true);
    }
  });

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleSetCurrentDataset = (id) => {
    const currentDataset = datasets.find((ds) => ds.id === id);
    console.log(currentDataset);
    setCurrentDataset(currentDataset);
    fetchFactorInfo(currentDataset.id);
    fetchVolcanoData(currentDataset.id);
  }
  const handleClickFactorButton = (idx) => {
    console.log(idx);
    setFactorNumber(idx);
  }

  return (
    <div>
      <Box
        sx={{ '& > :not(style)': { m: 0.5 } }}
        className='actionBox'
        style={{ right: 180 }}
      >
        <Fab
          variant="extended"
          aria-label="add"
          onClick={open ? handleDrawerClose : handleDrawerOpen}
        >
          Factor View
          <TableViewIcon />
        </Fab>
      </Box>
      <Drawer
        PaperProps={{
          sx: { height: .5 }
        }}
        sx={{
          flexShrink: 0,
        }}
        variant="persistent"
        anchor="bottom"
        open={open}
      >
        <Grid container spacing={3} sx={{ padding: 2, flexGrow: 1 }} >
          <Grid item xs={3}>
            <Autocomplete freeSolo
              options={datasets ? (datasets.map((ds) => ds.id)) : []}
              renderInput={(params) =>
                <TextField
                  {...params}
                  label="factor dataset"
                  size="small"
                  margin="dense"
                />}
              onChange={(e, data) => handleSetCurrentDataset(data)}
            />
            <Select
              value={factorNumber}
              size="small"
              style={{ width: '100%' }}
              onChange={(e) => handleClickFactorButton(e.target.value)}
            >
              {factorInfo ? Object.keys(factorInfo).map(key => {
                return (
                  <MenuItem key={key} value={key}>
                    {key in factorAliases ? factorAliases[key] : key}
                  </MenuItem>
                )
              }) : ""}
            </Select>
          </Grid>
          <Grid item xs={9} >
            <Grid container
              wrap="nowrap" spacing={1} sx={{ overflow: 'auto', marginTop: 0 }}
            >
              <Grid item xs={3}>
                {((factorInfo !== null) && (factorNumber !== null)) ?
                  <SingleFactorView
                    idx={factorNumber}
                    singleFactorInfo={factorInfo[factorNumber]}
                    factorNames={factorAliases}
                    setFactorNumber={setFactorNumber}
                  />
                  : ''
                }
              </Grid>
              <Grid item xs={9}>
                {volcanoData &&
                  <VolcanoPlot
                    volcanoData={volcanoData}
                    factorNumber={factorNumber}
                  />
                }
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Drawer>
    </div >
  );
}

const SingleFactorView = ({ idx, singleFactorInfo, factorNames, setFactorNumber }) => {
  const columns = [
    { field: 'gene', headerName: 'Gene', flex: 2, 
      disableClickEventBubbling: true,},
    { field: 'FoldChange', headerName: 'FoldChange', flex: 1, 
      type: 'number', disableClickEventBubbling: true, 
      valueFormatter: ({ value }) => value.toFixed(2),
    },
    { field: 'Chi2', headerName: 'Chi2', flex: 1, 
      type: 'number', disableClickEventBubbling: true, 
      hide: true
    },
    { field: 'pval', headerName: 'pval', flex: 1, 
      type: 'number', disableClickEventBubbling: true, 
      hide: false
    }

  ];

  return (
    Array.isArray(singleFactorInfo) ?
      <Grid container sx={{ flexGrow: 1 }}>
        <Grid item xs={12} style={{ fontWeight: 'bolder', fontSize: 'large', marginBottom: 5 }}>
          <div style={{ fontWeight: 'bolder', fontSize: 'medium', color: '#AAA', marginBottom: 2 }}>
            Factor #{idx}
          </div>
          <div style={{ fontWeight: 'bold', fontSize: 'small', color: '#555', marginBottom: 2 }}>
            {idx in factorNames ? factorNames[idx] : ''}
          </div>
        </Grid>
        <Grid container sx={{ borderBottom: 1, borderColor: '#AAA', marginBottom: 1 }} >
          <Grid item xs={12}>
            <DataGrid
              rows={singleFactorInfo}
              columns={columns}
              getRowId={(row)=> row.gene}
              rowHeight={25}
              hideFooter={true}
              autoHeight={true}
              density='compact'
              sx={{color:'#555'}}
            />
          </Grid>
        </Grid>
      </Grid>
      : ''
  )
}

export default FactorView;
