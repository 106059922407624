// functions for add/remove layers and sources
import { Protocol } from "pmtiles";
import * as pmtiles from "pmtiles";


export const bgLayerDefaults = {
  opacity: 0.5,
  brightnessMin: 0,
  brightnessMax: 1,
  contrast: 0,
  hueRotate: 0,
  bgColor: '#000000',
  layers: [
    {
      "format": "png",
      "label": "sDGE count",
      "tileDir": "tile/raster-count",
      "tilePaths": [
        "tile/raster-count/{z}/{x}/{y}.png"
      ],
      "isDisplayed": true
    }, {
      "format": "png",
      "label": "histology",
      "tileDir": "tile/raster-histology",
      "tilePaths": [
        "tile/raster-histology/{z}/{x}/{y}.png"
      ],
      "isDisplayed": true,
    }
  ]
};

export const addBgLayer = (map, dsn, index, layer) => {
  const host = `https://${dsn}.s3.amazonaws.com`;
  const sourceId = `basemap-${index}`;
  const layerId = `l-basemap-${index}`;
  if (layer.format == 'pmtiles') {
    const PMTILES_URL = `https://${dsn}.s3.amazonaws.com/${layer.tilePaths[0]}`;
    const protocol = new Protocol();
    const file = new pmtiles.PMTiles(PMTILES_URL);
    protocol.add(file);
    map.addSource(sourceId, {
      "type": "raster",
      "url": "pmtiles://" + PMTILES_URL
    });
  } else {
    map.addSource(sourceId, {
      type: 'raster',
      tiles: layer.tilePaths.map((p) => {
        return `${host}/${p}`
      }),
      tileSize: 256,
      scheme: 'tms',
      bounds: layer?.bounds ? layer.bounds : [-180, -90, 180, 90]
    });
  }
  map.addLayer({
    'id': layerId,
    'type': 'raster',
    'source': sourceId,
    'minzoom': 7,
    'maxzoom': 21,
    'layout': {
      'visibility': layer.isDisplayed ? 'visible' : 'none',
    },
    'paint': {
      'raster-opacity': bgLayerDefaults.opacity,
      'raster-contrast': 0,
    }
  });
}
