import axios from "axios";
import {
  exact,
  calculatePvalueFromZ,
} from "./ExactTest";


// get baseline gene frequency
export const getBaselineData = (freqUrl, setBaseFreq, setBaseRoi) => {
  // const folder = (catalog?.assets?.sge?.stat) ?  catalog.assets.sge.stat : "interim/vector-sdge/" 
  // const freqUrl = `${hostname}/${folder}sge_count.json`;
  axios.get(freqUrl, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
  }).then((res) => {
    if (res.status === 403) throw new Error ('403 error');
    else {
      return res.data
    };
  }).then((myJson) => {
    setBaseFreq(myJson);
    const baseKey = 'cnt_total';
    const roiBase = {
      'id': 'WholeLane',
      'label': `Baseline`,
      'boundary': {},
      'color': '#010101',
      'totalCount': myJson['sum'][baseKey],
      'countByGene': myJson['count'][baseKey],
      'sge': [],
      'loadState': 2
    };
    setBaseRoi([roiBase]);
  }).catch( 
    err => { console.log("No BaseFreq data")}
  );
}

export const object2array = (obj) => {
  return Object.keys(obj).map((g, id) => {
    return { 'id': id, 'gene_name': g, 'count': obj[g] }
  })
}

export const calculateOddsRatioIncluded = (target, comparison) => {
  // A: combined count (all genes, all pixels)
  // G: gene count (selected gene, all pixels)
  // R: ROI count (all genes, in ROI)
  // X: ROI-gene count (selected gene, in ROI)
  // Then the 2x2 contingency table for chi-square test will be:
  // a = X
  // b = R-X
  // c = G-X
  // d = A-G-R+X
  // And the OR  will be
  // OR = ad/bc
  // And the Z-score will be
  // Z = (ad-bc) / sqrt((a+b)(a+c)(c+d)(b+d))  * sqrt(a+b+c+d)

  const A = comparison['totalCount'];
  const R = target['totalCount'];

  const summary = Object.keys(target.countByGene).map((g, id) => {
    const X = target.countByGene[g];
    const G = comparison.countByGene[g];
    const a = X + 0.5;   // Yate's correction
    const b = R - X + 0.5;
    const c = G - X + 0.5;
    const d = A - G - R + X + 0.5;
    const OR = (a * d) / (b * c);
    const Z = (a * d - b * c) / Math.sqrt((a + b) * (a + c) * (c + d) * (b + d)) * Math.sqrt(a + b + c + d);
    const pvalue = calculatePvalueFromZ(Z);
    return {
      'id': id,
      'gene_name': g,
      'targetCount': X,
      'OR': isNaN(OR) ? -1 : OR,
      'pvalue': pvalue,
      'comparisonCount': G,
      'targetFreq': X / R,
      'comparisonFreq': G / A,
      'targetTotal': R,
      'comparisonTotal': A,
    }
  });
  const sorted = summary
    // .filter(g => g['count'] >= cutoff)
    .sort((a, b) => b['OR'] - a['OR']);
  return sorted
}

export const calculateOddsRatioDisjoint = (target, comparison) => {

  // Assuming that they are disjoint, the odds ratio / p-value should be calculated based on the following 2x2 contingency table
  // a = R1G
  // b = R1A-R1G
  // c = R2G
  // d = R2A-R2G
  // OR = (a*d)/(b*c)
  // Z = (a*d-b*c)/sqrt[ (a+b) * (b+c) * (a+c) * (b+d) ]

  const R1A = target['totalCount'];
  const R2A = comparison['totalCount'];

  const summary = Object.keys(target.countByGene).map((g, id) => {
    const R1G = target.countByGene[g];
    const R2G = comparison.countByGene[g];
    const a = R1G + 0.5;  // Yate's correction
    const b = R1A - R1G + 0.5;
    const c = R2G + 0.5;
    const d = R2A - R2G + 0.5;
    const OR = (a * d) / (b * c);
    const Z = (a * d - b * c) / Math.sqrt((a + b) * (a + c) * (c + d) * (b + d)) * Math.sqrt(a + b + c + d);
    let pvalue;
    if (Math.max(a, b, c, d) < 100) {
      pvalue = exact(a, b, c, d);
    } else {
      pvalue = calculatePvalueFromZ(Z);
    }
    return {
      'id': id,
      'gene_name': g,
      'targetCount': R1G,
      'OR': isNaN(OR) ? -1 : OR,   // when target has no matching gene
      'pvalue': pvalue,
      'comparisonCount': R2G,
      'targetFreq': R1G / R1A,
      'comparisonFreq': R2G / R2A,
      'targetTotal': R1A,
      'comparisonTotal': R2A,
    }
  });
  const sorted = summary
    // .filter(g => g['count'] >= cutoff)
    .sort((a, b) => b['OR'] - a['OR']);
  return sorted
}


