import React, { useEffect, useState, useContext } from 'react';

import { toMercator } from '@turf/projection';
import { toWgs84 } from '@turf/turf';
import { colorsTab10, } from "../Util";
import { getBaselineData } from './Data';
import Roi from './Roi';

import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import Drawer from '@mui/material/Drawer';
import TableViewIcon from '@mui/icons-material/TableView';
import Grid from '@mui/material/Grid';
import { GeneGrid } from './GeneGrid';
import { RegionLoadButton } from './RegionLoadButton';
import { RegionCreateButton } from './RegionCreateButton';
import { CatalogContext } from '../CatalogContext';

const RoiView = ({ map, dsn, fgbPaths, draw }) => {
  const catalog = useContext(CatalogContext);
  const [isMapMounted, setIsMapMounted] = useState(false);
  const [open, setOpen] = useState(false);
  const [baseFreq, setBaseFreq] = useState({});
  const [rois, _setRois] = useState([]);
  const roisRef = React.useRef(rois);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const host = `https://${dsn}.s3.amazonaws.com`;
    const folder = (catalog?.assets?.sge?.stat) ?  catalog.assets.sge.stat : "interim/vector-sdge/" 
    const freqUrl = `${host}/${folder}sge_count.json`;
  
    getBaselineData(freqUrl, setBaseFreq, setRois);
  }, []);

  useEffect(() => {
    if (map.current) {
      return;
    } else {
      setIsMapMounted(true);
    }
  }, []);

  const setRois = (rois) => {
    roisRef.current = rois;
    _setRois(rois);
  }

  const addRoi = (addedFeature, rois, isMercator = false) => {
    const newRoi = createRoi(isMercator ? addedFeature : toMercator(addedFeature), rois);
    createLayer(map.current, newRoi);
    setRois([...rois, newRoi]);
  }
  const onRegionCreate = (feature) => {
    console.log(feature.id);
    if (feature.id) {
      addRoi(feature, rois)
    }
  }

  const handleGeoJsonUpload = data => {
    console.log(data);
    if (data.features) {
      addRoi(data['features'][0], rois, isEPSG3857(data))
    } else {
      console.log("wrong format")
    }
  };

  return (
    <div>
      <Box
        sx={{ '& > :not(style)': { m: .5 } }}
        className='actionBox'
        style={{ right: 50 }}
      >
        <Fab
          variant="extended"
          aria-label="add"
          onClick={open ? handleDrawerClose : handleDrawerOpen}
        >
          ROI Pane
          <TableViewIcon />
        </Fab>
      </Box>
      <Drawer
        PaperProps={{ sx: { height: .3 } }}
        sx={{ flexShrink: 0, }}
        variant="persistent"
        anchor="bottom"
        open={open}
      >
        <Grid container >
          <Grid item xs={4} sx={{ padding: 1 }}>
            <Grid container >
              <Grid item xs={6}>
                Total SGE count:{baseFreq.hasOwnProperty('sum') ? baseFreq['sum']['cnt_total'] : 0}
                <br />
                # of Regions: {rois.length - 1}
              </Grid>
              <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }} >
                <RegionLoadButton onFileUpload={handleGeoJsonUpload} />
                <RegionCreateButton draw={draw} onRegionCreate={onRegionCreate} />
              </Grid>
              <Grid item xs={12}>
                  {roisRef.current.slice(1, rois.length).map((roi, idx) => {
                    return <Roi
                      key={idx}
                      idx={idx}
                      roi={roi}
                      id={roi.id}
                      rois={roisRef.current}
                      setRois={setRois}
                      map={map}
                      fgbSrcs={fgbPaths}
                      baseFreq={baseFreq}
                    />
                  })}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={8} sx={{ padding: 1 }}>
            <GeneGrid
              map={map.current}
              rois={rois}
            />
          </Grid>
        </Grid>
      </Drawer>
    </div >
  );
}

function isEPSG3857(geojson) {
  if (geojson.crs && geojson.crs.properties) {
    const crsName = geojson.crs.properties.name;
    return crsName === "EPSG:3857" || crsName === "urn:ogc:def:crs:EPSG::3857";
  } else {
    return false;
  }
}

const createRoi = (feature, rois) => {
  return {
    'id': feature.id,
    'label': `Region #${feature.id.slice(0, 5)}`,
    'boundary': feature,
    'color': colorsTab10[rois.length % 10],
    'totalCount': 0,
    'countByGene': {},
    'sge': [],
    'loadState': 0,
    'tableData': [],
    'source': `roi-fill-${feature.id}`,
    'layerFill': `l-roi-fill-${feature.id}`,
    'layerLine': `l-roi-line-${feature.id}`
  }
}

const createLayer = (map, roi) => {
  const source = {
    'type': 'geojson',
    'data': toWgs84(roi.boundary)
  };
  map.addSource(roi.source, source);
  map.addLayer({
    'id': roi.layerFill,
    'type': 'fill',
    'source': roi.source,
    'paint': {
      'fill-color': roi.color,
      'fill-opacity': 0.5,
    }
  });
  map.addLayer({
    'id': roi.layerLine,
    'type': 'line',
    'source': roi.source,
    'paint': {
      'line-color': '#886622',
      'line-width': 2,
      'line-dasharray': [1, 1]
    }
  });
};

export default RoiView;
