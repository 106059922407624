import React from 'react';

import Tooltip from '@mui/material/Tooltip';
import DownloadIcon from '@mui/icons-material/Download';

import { Button } from '@mui/material';

function useDownloadObjectAsJson() {
  return (exportObj, exportName, exportExt) => {
    const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(exportObj));
    const downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", exportName + exportExt);
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  };
}

export const DownloadJSONButton = ({ 
  label, data, icon, tooltip, 
  fileName, fileExt='.json', className }) => {

  const downloadObjectAsJson = useDownloadObjectAsJson();
  return (
    <Tooltip title={tooltip}>
      <Button 
        startIcon={icon}
        size="small"
        color="secondary"
        onClick={() => downloadObjectAsJson(data, fileName, fileExt)}
        className={className}
      >
        {label} 
      </Button>
    </Tooltip>
  );
}

export const DownloadBoundaryButton = ({features, fileName}) => {
  return (
    <DownloadJSONButton
      tooltip='download boundary'
      label='boundary'
      icon=<DownloadIcon/>
      data={
        {
          'type': 'FeatureCollection',
          'crs': {
            "type": "name",
            "properties": {
              "name": "urn:ogc:def:crs:EPSG::3857"
            }
          },
          'features': features
        }
      }
      fileName={fileName}
      fileExt='.geojson'
      // className= {loadState.label==='loaded' ? '' : 'disable fade'}
    />
  )
}

export const DownloadSgeButton = ({features, fileName, className}) => {
  return (
    <DownloadJSONButton
      tooltip='download extracted sge'
      label='SGE'
      icon=<DownloadIcon/>
      data={
        {
          'type': 'FeatureCollection',
          'crs': {
            "type": "name",
            "properties": {
              "name": "urn:ogc:def:crs:EPSG::3857"
            }
          },
          'features': features
        }
      }
      fileName={ fileName }
      fileExt='.geojson'
      className={className}
    />
  )
}
