import React, { useEffect, useState, useContext, useRef } from 'react';
import Grid from '@mui/material/Grid';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';

import * as dfd from "danfojs";
// import { MapContext } from '../MapContext';
// import { CatalogContext } from '../CatalogContext';

import {
  Chart as ChartJS,
  LinearScale,
  LogarithmicScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from 'chart.js';

import { Scatter } from 'react-chartjs-2';

ChartJS.register(
  LinearScale,
  LogarithmicScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend);

export const DEViewer = ({ dataset, df }) => {
  // const map = useContext(MapContext);
  // const catalog = useContext(CatalogContext);
  const [dfsub, setDfsub] = useState(null);
  const [factors, setFactors] = useState([]);
  const [factorNumber, setFactorNumber] = useState(0);
  const fileInput = useRef();

  useEffect(() => {
    if (df) {
      setFactors(df["factor"].unique().sortValues().values.map((f) => { return { id: f, alias: '' } }));
      const newDfsub = df.iloc({ rows: df["factor"].eq(factorNumber) });
      setDfsub(newDfsub);
    }
  }, [df])

  useEffect(() => {
    if (dfsub) {
      const newDfsub = df.iloc({ rows: df["factor"].eq(factorNumber) });
      setDfsub(newDfsub);
    };
  }, [factorNumber])

  const handleFileChosen = (event) => {
    let file = event.target.files[0];
    dfd.readCSV(file).then(cdf => {
      const data = dfd.toJSON(cdf, { format: 'column' });
      setFactors(data);
    });
  }

  return (
    <Grid container
      wrap="nowrap" spacing={1} sx={{ overflow: 'auto', marginTop: 0 }}
    >
      <Grid item xs={2}>
        <div>
          <button onClick={() => { fileInput.current.click() }}>Load Alias</button>
          <input
            type='file'
            ref={fileInput}
            style={{ display: 'none' }}
            onClick={(event) => { event.target.value = null }}
            onChange={handleFileChosen}
          />
        </div>

        <FactorTable
          factors={factors}
          setFactorNumber={setFactorNumber} />
      </Grid>
      <Grid item xs={5}>
        <div style={{
          wordWrap: 'break-word',
          padding: 1,
        }}>
          {dataset.split("/").pop()}
        </div>
        <div>Factor #{factorNumber}</div>
        <TopGeneGrid dfsub={dfsub} />
      </Grid>
      <Grid item xs={5}>
        <VolcanoPlot dfsub={dfsub} />
      </Grid>
    </Grid>
  );
}

const FactorTable = React.memo(({ factors, setFactorNumber }) => {
  const columns = [
    { field: 'id', headerName: 'id', flex: 1 },
    { field: 'alias', headerName: 'alias', flex: 2, editable: true }];
  const handleRowClick = (e) => {
    setFactorNumber(e.row.id);
  }
  return <DataGrid
    initialState={{
      pagination: { paginationModel: { pageSize: 20 } },
    }}
    disableRowSelectionOnClick
    components={{ Toolbar: CustomToolbar, }}
    onRowClick={handleRowClick}
    rows={factors}
    columns={columns}
    getRowId={(row) => row.id}
    pageSize={20}
    rowHeight={25}
    hideFooter={false}
    autoHeight={true}
    density='compact'
    sx={{ color: '#555' }}
  />
});
const CustomToolbar = () => {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

const TopGeneGrid = ({ dfsub }) => {

  const columns = [
    {
      field: 'gene', headerName: 'gene', flex: 2,
      disableClickEventBubbling: true,
    },
    {
      field: 'FoldChange', headerName: 'FoldChange', flex: 1,
      type: 'number', disableClickEventBubbling: true,
      valueFormatter: ({ value }) => {
        if (Number.isFinite(value)) {
          return value.toFixed(2);
        } else if (value === Infinity || value === -Infinity || value === 'inf') {
          return 'inf';
        } else {
          return 'N/A';
        }
      }, 
    },
    {
      field: 'Chi2', headerName: 'Chi2', flex: 1,
      type: 'number', disableClickEventBubbling: true,
      hide: false
    },
    {
      field: 'pval', headerName: 'pval', flex: 1,
      type: 'number', disableClickEventBubbling: true,
      hide: false
    },
    {
      field: 'gene_total', headerName: 'gene_total', flex: 1,
      type: 'number', disableClickEventBubbling: true,
      hide: false
    }
  ];

  const data = dfsub ? dfd.toJSON(dfsub, { format: 'column' }) : {};

  return <DataGrid
    initialState={{
      pagination: { paginationModel: { pageSize: 20 } },
    }}
    rows={data}
    columns={columns}
    getRowId={(row) => row.gene}
    pageSize={20}
    rowHeight={25}
    hideFooter={false}
    autoHeight={true}
    density='compact'
    sx={{ color: '#555' }}
  />
}

const VolcanoPlot = ({ dfsub }) => {

  const options = {
    layout: { padding: { bottom: 60 } },
    maintainAspectRatio: false,
    responsive: true,
    elements: {
      point: {
        backgroundColor: "#f26bb5D3",
        hoverRadius: 8
      }
    },
    parsing: {
      xAxisKey: 'FoldChange',
      yAxisKey: '-log(pval)',
    },
    scales: {
      x: {
        type: 'linear',
        display: false,
        grid: { display: false }
      },
      x: {
        title: {
          text: "Fold Change",
          display: true
        },
        type: 'logarithmic',
        position: 'bottom',
      },
      y: {
        type: 'linear',
        reverse: true,
        display: false,
        grid: { display: false }
      },
      y: {
        title: {
          text: `-log10(P-Value)`,
          display: true
        },
        type: 'linear',
        position: 'left',
      },
    },
    interaction: { mode: 'nearest' },
    plugins: {
      title: {
        display: true,
        text: 'Volcano Plot',
      },
      legend: { display: false },
      tooltip: {
        callbacks: {
          title: (tooltipItem) => { return tooltipItem[0].raw.gene },
        }
      }
    }
  };
  const data = {
    datasets: [
      { data: dfsub ? dfd.toJSON(dfsub, { format: 'column' }) : null }
    ]
  };
  return <Scatter data={data} options={options} />;
}

