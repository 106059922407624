import React, {
  useRef, useEffect, useState, useContext
} from 'react';
import ReactDOM from 'react-dom';
import {
  useParams,
} from "react-router-dom";

import yaml from 'js-yaml';
import mapboxgl from 'maplibre-gl';
import { Protocol } from "pmtiles";
import MapboxDraw from "@mapbox/mapbox-gl-draw";

import { CatalogContext } from './CatalogContext';
import { MapContext } from './MapContext';

import { RoiDrawStyles } from './Roi/Config';
import proj4 from 'proj4';
import ScaleControl from './ScaleControl';
import Background from './Background/Background';
import { bgLayerDefaults, addBgLayer } from './Background/LayerControl';
import Tooltip from './Tooltip';

import RoiView from './Roi/RoiView';
import FactorDEView from './Factor/FactorDEView';
import FactorView from './Factor/FactorView';
import FactorControlPanel from './Factor/FactorControlPanel';
// import SgeControlPanel from './Sge/SgeControlPanel';
import SgeControlPanel from './SgePm/SgeControlPanel';
import RasterControlPanel from './Raster/RasterControlPanel';
import GeneListView from './Sge/GeneListView';

import { DataListContext } from '../AppContext';

import {
  isSampleDataset,
  isUserAllowed,
} from "./Util";

import 'maplibre-gl/dist/maplibre-gl.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import './map.css'

export const MapView = ({ user }) => {
  const datalist = useContext(DataListContext);
  const [dataset, setDataset] = useState({});
  const [catalog, setCatalog] = useState(null);
  let { dsn } = useParams();
 
  let protocol = new Protocol();
  mapboxgl.addProtocol("pmtiles", protocol.tile);

  const getData = () => {
    if (dsn) {
      datalist.map((d) => {
        if (d.id === dsn) {
          setDataset(d);
          fetchCatalog(d);
        };
      })
    }
  }

  const fetchCatalog = async (d) => {
    const catalog = d?.catalog ? d.catalog : "catalog.yaml"
    const url = `https://${dsn}.s3.amazonaws.com/${catalog}`;
    try {
      const response = await fetch(url);
      const text = await response.text();
      const parsedData = yaml.load(text);
      if (typeof parsedData === 'object') {
        setCatalog(parsedData);
      } else {
        setCatalog({'id': dsn});
      }
    } catch (e) {
      console.log(e);
      setCatalog({});
    }
  }

  // get dataset info
  useEffect(() => {
    getData();
  }, []);

  if (
    (Object.keys(dataset).length > 0) &&
    (isSampleDataset(dsn) || isUserAllowed(dataset, user))
  ) {
    if (catalog === null) {
      return <div>loading...</div>
    } else {
      return (
        <div className="App">
          <Map
            dataset={dataset}
            catalog={catalog}
            user={user}
            dsn={dsn}
          />
        </div>
      );
    }
  } else {
    return (<div> You are not allowed to access this dataset. </div>)
  }
}

const Map = ({ dataset, catalog, user, dsn }) => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const draw = useRef(null);
  const tooltipRef = useRef(new mapboxgl.Popup({ offset: 15 }));
  const [viewState, setViewState] = useState(
    (catalog && ('initViewState' in catalog)) ? catalog.initViewState : {
      // {
      "lng": 0.800,
      "lat": 0.005,
      "zoom": 14
    }
  );
  useEffect(() => {
    // initial load
    console.log("Catalog::", catalog);
    if (map.current) {
      return;
    }
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: {
        version: 8,
        sources: {},
        layers: [
          {
            id: 'background',
            type: 'background',
            paint: { 'background-color': bgLayerDefaults.bgColor }
          },
        ],
      },
      center: [viewState.lng, viewState.lat],
      zoom: viewState.zoom
    });
    map.current.addControl(new mapboxgl.NavigationControl());
    map.current.addControl(new ScaleControl({
      maxWidth: 180,
      unit: 'um'
    }), 'bottom-right');

    // add sources and layers
    map.current.on('load', () => {
      // add base map
      const layers = catalog?.assets?.background ?
        catalog.assets.background :
        bgLayerDefaults['layers'];
      layers.map((layer, index) => {
        addBgLayer(
          map.current,
          dsn,
          index,
          layer
        )
      });
      //add drawing 
      draw.current = new MapboxDraw({
        displayControlsDefault: false,
        controls: {
          polygon: true,
          trash: true,
        },
        styles: RoiDrawStyles
      });
      map.current.addControl(draw.current, 'top-right');

    });

    // coordinate display
    map.current.on('move', () => {
      setViewState({
        lng: map.current.getCenter().lng,
        lat: map.current.getCenter().lat,
        zoom: map.current.getZoom()
      });
    });
    
    map.current.on('error', e => {
      if (e.error.status !== 403)  {
        console.log("not 403");
        console.log(e);
      } 
    });

    // tooltip
    map.current.on('click', e => {
      const features = map.current.queryRenderedFeatures(e.point);
      if (features.length &&
        features[0].layer.id.slice(0, 2) == 'l-' &&
        draw.current.getMode() === 'simple_select') {
        const feature = features[0];
        // Create tooltip node
        const tooltipNode = document.createElement('div');
        ReactDOM.render(<Tooltip feature={feature} />, tooltipNode);
        // Set tooltip on map
        tooltipRef.current
          .setLngLat(e.lngLat)
          .setDOMContent(tooltipNode)
          .addTo(map.current);
      }
    });
  }, []);

  return (
    <div className="map-wrap">
      <CatalogContext.Provider value={catalog}>
      <MapContext.Provider value={map}>
        <div className="sidebar">
          <h1 className="heading"><a href="/index.html">CartoScope</a></h1>
          <div style={{ color: "gray" }}>{dsn}</div>
          <hr/>
          <SgeControlPanel
            map={map}
            dsn={dsn}
            sgeTilePaths={dataset.sgeTilePaths}
          />
          <hr />
          <FactorControlPanel
            map={map}
            dsn={dsn}
          />
          <hr />
          <RasterControlPanel/>
          <hr />
          <Background
            map={map}
            dsn={dsn}
          />
          <hr />
          <div style={{ color: "gray" }}>
            X:{
              proj4("EPSG:4326", "EPSG:3857").forward({
                x: viewState.lng, y: viewState.lat
              }).x.toFixed(0)
            }({viewState.lng.toFixed(6)
            })<br />
            Y:{
              proj4("EPSG:4326", "EPSG:3857").forward({
                x: viewState.lng, y: viewState.lat
              }).y.toFixed(0)
            }({viewState.lat.toFixed(6)
            })
            <br />
            Zoom:{viewState.zoom.toFixed(2)}</div>
        </div>
        <RoiView
          map={map}
          dsn={dsn}
          fgbPaths={
            catalog?.assets?.sge?.fullDepth ?
             [`https://${dsn}.s3.amazonaws.com/${catalog.assets.sge.fullDepth}`] :   
              catalog?.assets?.fullGeneFgb ?
              catalog.assets.fullGeneFgb :
                'fgbPaths' in dataset ?
                  dataset.fgbPaths :
                  [`https://${dsn}.s3.amazonaws.com/vector/full_sdge.fgb`]
          }
          draw={draw}
        />
        { catalog?.assets?.factor?.DE ? <FactorDEView/> : <FactorView map={map} dsn={dsn}/>}
        <GeneListView
          map={map}
          dsn={dsn}
        />
      </MapContext.Provider>
      </CatalogContext.Provider>
      <div ref={mapContainer} className="map" />
    </div >
  );
}

export default MapView;

