// a panel UI for SGE layer opacity, radius 
import { useState, useContext } from 'react';
import { MapContext } from '../MapContext';
import { LayerPanel } from '../CommonUI/Panel.js';
import { RasterSourceControl } from './RasterSourceControl';
import { RasterStyleControl } from './RasterStyleControl.js';

export const RasterLayerPanel = ({ layerInfo, removeLayer, layers, setLayers }) => {

  const map = useContext(MapContext);
  const [state, setState] = useState({
    ...layerInfo,
    map: map,
    layerName: `Raster`,
  });


  return (
    <div>
      <LayerPanel state={state} setState={setState} removeLayer={removeLayer}>
        <RasterSourceControl
          layers={layers} setLayers={setLayers}
          buttonLabel="update"
          layerInfo={state}
        />
        <hr />
        <RasterStyleControl
          layerInfo={state} setLayerInfo={setState} />
      </LayerPanel>
    </div>
  )

}


export default RasterLayerPanel;
