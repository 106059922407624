import React, { useEffect, useState, useContext } from 'react';

import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import { useTheme } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import TableViewIcon from '@mui/icons-material/TableView';
import { DataGrid, GridToolbar, GridColTypeDef } from '@mui/x-data-grid';

import { CatalogContext } from '../CatalogContext';


const GeneListView = ({ map, dsn }) => {

  const catalog = useContext(CatalogContext);
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [geneList, setGeneList] = useState([]);

  const fetchGeneList = async () => {
    const folder = (catalog?.assets?.sge?.stat) ?  catalog.assets.sge.stat : "interim/vector-sdge/" 
    const url = `https://${dsn}.s3.amazonaws.com/${folder}sge_count.json`;
    const res = await fetch(url);
    const rawData = await res.json();
    const obj = rawData['count']['cnt_total'];
    const data = Object.entries(obj).map(([key, value]) => ({ 'gene': key, 'count': value }))
    setGeneList(data)
  }
  useEffect(() => {
    fetchGeneList();
  }, [open]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const columns = [
    {
      field: 'gene', headerName: 'Gene', flex: 2,
      disableClickEventBubbling: true,
    },
    {
      field: 'count', headerName: 'Count', flex: 1,
      type: 'number', disableClickEventBubbling: true
    }
  ];

  return (
    <div>
      <Box
        sx={{ '& > :not(style)': { m: 0.5 } }}
        className='actionBox'
        style={{ right: 340 }}
      >
        <Fab
          variant="extended"
          aria-label="add"
          onClick={open ? handleDrawerClose : handleDrawerOpen}
        >
          Gene List
          <TableViewIcon />
        </Fab>
      </Box>
      <Drawer
        PaperProps={{
          sx: { width: 300, height: .8, marginTop: 13 }
        }}
        sx={{
          flexShrink: 0,
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <Grid container spacing={0} sx={{ padding: 0, flexGrow: 1 }} >
          <DataGrid
            disableRowSelectionOnClick
            rows={geneList}
            columns={columns}
            getRowId={(row) => row.gene}
            rowHeight={25}
            hideFooter={false}
            autoPageSize={true}
            density='compact'
            sx={{ color: '#555' }}
            components={{
              Toolbar: GridToolbar,
            }}
            initialState={{
              sorting: {
                sortModel: [{ field: 'count', sort: 'desc' }],
              },
            }}
          />
        </Grid>
      </Drawer>
    </div >
  );
}

export default GeneListView;
