import React, { useEffect } from 'react';

import Stack from '@mui/material/Stack';
import { Button } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

import DeleteIcon from '@mui/icons-material/Delete';
import FitScreenIcon from '@mui/icons-material/FitScreen';
import IconButton from '@mui/material/IconButton';
import { geojson } from "flatgeobuf";
import { bbox, pointsWithinPolygon, toWgs84 } from '@turf/turf';
import { useState } from 'react';

import { DownloadSgeButton, DownloadBoundaryButton } from '../CommonUI/DownloadButton';


const Roi = ({ idx, roi, id, rois, setRois, map,
  fgbSrcs, initCount }) => {

  const [count, setCount] = useState(initCount);
  const [color, setColor] = useState(roi.color);
  const loadStates = {
    0: {
      'label': 'Extract SGE',
      'className': "",
      'lineColor': "#A32"
    },
    1: {
      'label': 'Extracting...',
      'className': "fade disable",
      'lineColor': "#772"
    },
    2: {
      'label': 'Extract SGE',
      'className': "",
      'lineColor': "#222"
    }
  }
  const [loadState, setLoadState] = useState(loadStates[0]);

  useEffect(() => {
    map.current.setPaintProperty(
      roi.layerLine,
      'line-color',
      loadState['lineColor'],
    );
  }, [loadState]);


  const deleteRoi = (deletedRoi, rois) => {
    // const deletedIds = deletedFeatures.map(f => f.id);
    const filteredRois = rois.filter(roi => deletedRoi.id !== roi.id);
    setRois(filteredRois);
    deleteRoiFillLayer(map.current, deletedRoi.id);
  }
  const deleteRoiFillLayer = (map, idx) => {
    const sourceId = `roi-fill-${idx}`;
    const layerFill = `l-roi-fill-${idx}`;
    const layerLine = `l-roi-line-${idx}`;
    if (map.getLayer(layerFill)) {
      map.removeLayer(layerFill);
      map.removeLayer(layerLine);
      map.removeSource(sourceId);
    }
  }

  const summarize = (features) => {
    console.log("summarize!!!!!");
    const counts = {};
    const sge = {};
    let totalCount = 0;

    for (let i = 0; i < features.length; ++i) {
      const feature = features[i];
      const cnt = feature.properties.cnt_total;
      totalCount += cnt;

      if (feature.properties.gene_name in counts) {
        counts[feature.properties.gene_name] += cnt;
      }
      else {
        counts[feature.properties.gene_name] = cnt;
      }
      if (feature.properties.gene_name in sge) {
        sge[feature.properties.gene_name].push(feature);
      }
      else {
        sge[feature.properties.gene_name] = [feature];
      }
    }
    updateRois({
      ...roi,
      countByGene: counts,
      totalCount: totalCount,
      sge: sge,
      features: features,
    }, rois)
  }

  const initializeCount = (roi) => {
    setCount(null);
    updateRois({
      ...roi,
      countByGene: {},
      totalCount: 0,
      sge: {},
      features: null,
    }, rois)
  }
  
  const handleClickGetData = () => {
    console.log(roi);
    initializeCount(roi);
    setLoadState(loadStates[1]);
    const boundsList = bbox(roi.boundary);
    const bounds = {
      maxX: boundsList[2],
      maxY: boundsList[3],
      minX: boundsList[0],
      minY: boundsList[1],
    };
    loadFGB(fgbSrcs, bounds, setCount)
      .then((res) => {
        console.log("pt in polygon");
        const pointsWithin = pointsWithinPolygon(res, roi.boundary);
        summarize(pointsWithin.features);
        setCount(pointsWithin.features.length);
      })
      .catch(e => {
        console.log("error!", e);
        setLoadState(loadStates[0]);
      })
      .finally(() => {
        setLoadState(loadStates[2]);
      });
  };

  const handleColorChange = (e) => {
    const color = e.target.value;
    setColor(color);
    updateRois({
      ...roi,
      color: color,
    }, rois)

    map.current.setPaintProperty(
      roi.layerFill,
      'fill-color',
      color,
    );
  }

  const updateRois = (updatedRoi, rois) => {
    let updatedRois = rois.map(roi => {
      if (roi.id == updatedRoi.id) {
        console.log("update!!!");
        return updatedRoi;
      }
      return roi; // else return unmodified item 
    });
    setRois(updatedRois);
  }

  return (
    <div className="Roi">
      <hr className='thin' />
      <div style={{ display: 'flex', alignItems:'center', height:30}}>
        <input type="color" value={roi.color} onChange={handleColorChange} />
        <h4> Region #{id.slice(0, 5)}</h4>
        <div style={{ marginLeft: 'auto' }}>
          <Stack direction="row" alignItems="center" spacing={0}>
            <Tooltip title="Extract gene expressions within region">
              <Button
                size="small" variant="outlined" color="secondary"
                onClick={handleClickGetData}
                className={loadState['className']}
              > {loadState['label']} 
              </Button>
            </Tooltip>
            <Tooltip title="Delete region">
              <IconButton aria-label="delete">
                <DeleteIcon
                  size="small" color="secondary"
                  onClick={() => deleteRoi(roi, rois)}
                />
              </IconButton>
            </Tooltip>
          </Stack>
        </div>
      </div>
      <div style={{ display: 'flex', alignItems:'center', height:30}}>
        <div>
          SGE count:{count}
        </div>
        <div style={{ marginLeft: 'auto' }}>
          <Stack direction="row" alignItems="center" spacing={0}>
            <Tooltip title="zoom to region">
              <IconButton aria-label="delete">
                <FitScreenIcon
                  size="small"
                  color="secondary"
                  onClick={() => zoomto(map, roi)}
                />
              </IconButton>
            </Tooltip>
            <DownloadBoundaryButton 
              features={[roi.boundary]}
              fileName={'bd-' + roi.id}
            />
            <DownloadSgeButton 
              features={roi.features}
              fileName={'sge-' + roi.id}
              className={loadState.label === 'Extract SGE' ? '' : 'disable fade'}
            />
          </Stack>
        </div>
      </div>
    </div>
  )
}

const zoomto = (map, roi) =>{
  const featureBounds = bbox(roi.boundary);
  const convertedBounds = [
    toWgs84([featureBounds[0], featureBounds[1]]),
    toWgs84([featureBounds[2], featureBounds[3]])
  ];
  console.log(convertedBounds);
  map.current.fitBounds(convertedBounds, {
    padding: {top: 300, bottom: 300, left: 500, right: 300}
  });
}

const loadFGB = async (fgb_srcs, bounds, counter) => {
  const results = [];
  console.log(fgb_srcs);
  for await (var fgb_src of fgb_srcs) {
    console.log(fgb_src);
    const iter = geojson.deserialize(
      fgb_src,
      bounds,
      (meta) => { console.log(meta); });
    for await (let f of iter) {
      results.push(f);
      // update counter every 977 results to look random
      if (results.length % 977 === 12) {
        counter(results.length);
      }
    }
    counter(results.length);
  }
  return {
    'type': 'FeatureCollection',
    'features': results
  }
};

export default Roi;
