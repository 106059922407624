import React, { useState, useContext } from 'react';

import { CatalogContext } from '../CatalogContext';
import { CollapsiblePanel } from '../CommonUI/Panel.js';
import { bgLayerDefaults, addBgLayer } from './LayerControl';
import { BgLayerPanel } from './BgLayerPanel';
import { v4 as uuidv4 } from 'uuid';


const Background = ({ map, dsn }) => {

  const catalog = useContext(CatalogContext);
  const [bgColor, setBgColor] = useState(bgLayerDefaults['bgColor']);
  const [layers, setLayers] = useState(
    catalog?.assets?.background ?
      catalog.assets.background :
      bgLayerDefaults['layers']);

  const removeLayer = (id) => {
    // remove layer from currentLayers
    const updatedLayers = layers.filter(l => l.id !== id);
    setLayers(updatedLayers);
  }
  
  const handleColorChange = (value) => {
    // const value = e.target.value;
    map.current.setPaintProperty(
      'background', 'background-color', value);
    setBgColor(value);
  };

  return (
    <CollapsiblePanel
      heading='Background'
      initActive={false}>
      <div>
        <label
          style={{ display: 'flex' }}
        >
          Background color 
          <input
            style={{ marginLeft: 'auto'}}
            type="button"
            value='Black'
            onClick={()=>handleColorChange('#000')}
          />
          <input
            type="button"
            value='White'
            onClick={()=>handleColorChange('#FFF')}
          />
        </label>
        {
          layers.map((layerInfo, index) => {
            return (
              <div key={layerInfo.label}>
                <BgLayerPanel
                  map={map}
                  index={index}
                  layerInfo={layerInfo}
                  removeLayer={removeLayer}
                />
              </div>)
          })
        }
      </div>
    </CollapsiblePanel>
  )
};

const BgLayerButton = ({ layer, handleBgButtonClick }) => {
  const [display, setDisplay] = useState(layer.display);
  const handleClick = (e) => {
    setDisplay(!display);
    e.preventDefault();
    handleBgButtonClick(e);
  }
  return (<button
    id={layer.id}
    onClick={handleClick}
    className={display ? "" : "fade"}
  >{layer.label}</button>)
}

export default Background;
