// functions for add/remove layers and sources

export const styleParamsDefaults = {
  'raster-opacity': 0.5,
  'raster-brightness-min': 0,
  'raster-brightness-max': 1,
  'raster-contrast': 0,
};

export const sourceParamsDefaults = {
  url: "https://hd95-hvwf7-mouse-l1.s3.amazonaws.com/raster/factor/HD95L1_F12_D36_cog_r2.tif",
  rescale: "0,1",
  expression: "b1",
  colormap_text: "_topk",
  colormap: {}
};

export const layerDefaults = {
  isDisplayed: true,
  styleParams: styleParamsDefaults,
  sourceParams: sourceParamsDefaults
};


export const addRasterLayer = (map, layerInfo) => {
  // layer name convention
  // layer: l-raster-uuid
  // source: s-raster-uuid

  const cogBaseEndPoint = 'https://ou60yuvp0i.execute-api.us-east-1.amazonaws.com/cog';
  const layerId = layerInfo['layerId'];
  const sourceId = layerInfo['sourceId'];

  if (map.getLayer(layerId)) map.removeLayer(layerId);
  if (map.getSource(sourceId)) map.removeSource(sourceId);

  const params = layerInfo.sourceParams;
  const url_params = createParamString(params);
  const endpoint = `${cogBaseEndPoint}/info?${url_params}`;

  const fetchInfo = async () => {
    const res = await fetch(endpoint);
    const data = await res.json();
  }
  fetchInfo();

  map.addSource(
    sourceId, {
    'type': 'raster',
    'tiles': [`${cogBaseEndPoint}/tiles/{z}/{x}/{y}.png?${url_params}`],
    'minzoom': 13,
    'maxzoom': 21,
    // 'bounds': [0.62234, 0.01141, 1.04066, 0.03167],
    'tileSize': 256,
  }
  );

  map.addLayer({
    'id': layerId,
    'type': 'raster',
    'source': sourceId,
    'paint': layerInfo.styleParams
  });
}

const createParamString = (obj) => {
  const hasColormap = obj.colormap !== undefined && obj.colormap !== '';

  return Object.keys(obj)
    .filter(key => {
      if (hasColormap && (key === 'colormap_name' || key === 'rescale')) {
        return false;
      }
      return true;
    })
    .map(key => `${key}=${obj[key]}`)
    .join('&');
}

export const deleteFactorLayer = (map, layerInfo) => {
  if (map.getLayer(layerInfo.layerId)) {
    map.removeLayer(layerInfo.layerId);
    map.removeSource(layerInfo.layerId);
  }
}
