import React, { useEffect, useState } from 'react';
// import { ScatterChart, Scatter, XAxis, YAxis,ZAxis, ReferenceLine, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import {
  Chart as ChartJS,
  LinearScale,
  LogarithmicScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from 'chart.js';

import { Scatter } from 'react-chartjs-2';

ChartJS.register(
  LinearScale, 
  LogarithmicScale,
  PointElement, 
  LineElement, 
  Tooltip, 
  Legend);


export const VolcanoPlot = ({dsn, volcanoData, factorNumber}) => {
  
  // const [factorNumber, setFactorNuimber] = useState(factorNumber);
  const options = {
    responsive: true,
    elements: {
      point: {
        backgroundColor: "#f26bb5D3",
        hoverRadius: 8
      }
    },
    parsing: {
      xAxisKey: 'FoldChange',
      yAxisKey: 'nl_pval',
      // yAxisKey: 'pval',
    },
    scales: {
      x: {
        type: 'linear',
        display: false,
        grid: {
          display: false
        }
      },
      x: {
        title: {
          text: "Fold Change", 
          display: true
        },
        type: 'logarithmic',
        position: 'bottom',
      },
      y: {
        type: 'linear',
        reverse: true,
        display: false,
        grid: {
          display: false
        }
      },
      y: {
        title: {
          text: `-log10(P-Value)`,
          display: true
        },
        type: 'linear',
        position: 'left',
      },
      // y: {
      //   title: {
      //     text: `P-Value`,
      //     display: true
      //   },
      //   type: 'logarithmic',
      //   position: 'left',
      //   reverse: true,
      //   ticks: {
      //     callback: (val) => (val.toExponential())
      //   },
      // }
    },
    interaction: {
      mode: 'nearest'
    },
    plugins: {
      title: {
        display: true,
        // text: `Volcano Plot - Factor ${factorNumber}`,
        text: 'Volcano Plot',
      },
      legend: {
        display: false
      },
      tooltip: {
        callbacks: {
          title: (tooltipItem) => {
            return tooltipItem[0].raw.gene
          },
        }
      }
    }
  };
  const data = {
    datasets: [
      {data: volcanoData[factorNumber]}
    ]
  };
  return <Scatter data={data} options={options} />;
} 

export default VolcanoPlot;
