import React from 'react';
import { useState } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import IconButton from '@mui/material/IconButton';

import { toWgs84 } from '@turf/turf';

export default function RoiGeneDrawButton({ roi, param, map }) {
  // const [roi, setRoi] = React.useState(roi);
  const [visible, setVisible] = useState(false);

  const handleClick = (event) => {
    setVisible(!visible);
    const g = param.gene_name;
    const layerIdx = `${roi.id.slice(0, 5)}-${g}`;
    if (visible) {
      deleteLayer(map, layerIdx);
    } else {
      const geojson = toWgs84(
        { "type": "FeatureCollection", "features": roi['sge'][g] }
      );
      console.log(geojson);
      createLayer(map, geojson, layerIdx);
    }
  };

  const createLayer = (map, geojson, idx) => {
    const source = {
      'type': 'geojson',
      'data': geojson
    };
    const lname = `roi-gene-${idx}`;
    map.addSource(lname, source);
    map.addLayer({
      'id': 'l-' + lname,
      'type': 'circle',
      'source': `roi-gene-${idx}`,
      'paint': {
        // 'circle-color': roi.color,
        'circle-color': "#f5ce42",
        'circle-opacity': 0.9,
        'circle-radius': [
          "interpolate",
          ["exponential", 2],
          ["zoom"],
          0, 0,
          21, ['*', 27, 1]
        ],
        'circle-stroke-color': '#202020',
        'circle-stroke-width': 1.5,
      }
    });
  };
  const deleteLayer = (map, idx) => {
    const sourceId = `roi-gene-${idx}`;
    const layerId = `l-roi-gene-${idx}`;
    if (map.getLayer(layerId)) {
      map.removeLayer(layerId);
      map.removeSource(sourceId);
    }
  }


  return (
    <div>
      <IconButton
        // color="secondary"
        style={{ color: visible ? 'rgb(50, 100, 200' : 'rgb(200,200,200)' }}
        aria-label="display gene"
        component="span"
        onClick={handleClick}
      >
        <VisibilityIcon />
      </IconButton>
      {param.gene_name}
    </div >

  )
}

